import React from 'react'
import { components, constants, useTranslation, useServices } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'
import Box from '@material-ui/core/Box'
import AciHighwayManifestApiUrls from 'src/apiUrls/AciHighwayManifestApiUrls'
import FaxCoverSheetFormProperties from './FaxCoverSheetFormProperties'
import { FileForUserGetCurrentLoginId } from 'src/common/FileForUserCommon'

const {
  button: { CngButton },
  form: {
   CngForm
  },
  CngDialog,
} = components

const { FormState } = constants

function FaxCoverSheetDialog(props) {
  const {
    openDialog,
    onClose,
    showNotification
  } = props

  const { initialValues, makeValidationSchema } = FaxCoverSheetFormProperties.formikProps
  const {securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.ACIHWY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let faxTitle = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.FAX_COVERSHEET
    )

    let sendButton = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEND_BUTTON
    )

    return { faxTitle, sendButton }
  }

  function handleEmailCoverSheet(data){
    try {
      securedSendRequest.execute(
        'POST',
        AciHighwayManifestApiUrls.FAX_COVERSHEET,
        data,
        (response) => {
          if (response.status === 200) {
            if (response.data) {
              const errorMessages = response.data.errorMessages

              if (!errorMessages) {
                showNotification(
                  'success',
                  'CoverSheet has been sent successfully'
                )
                onClose()
              } else {
                errorMessages.forEach((message) =>
                  showNotification('error', message)
                )
              }
            }
          }
        },
        () => {
          showNotification('error', 'Something went wrong when sending CoverSheet.')
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <CngDialog
      dialogContent={
        <CngForm
          formikProps={{
            initialValues: {
              ...(initialValues)
            },
            makeValidationSchema: makeValidationSchema,
            onSubmit: (data) => {
                data.tripNo = openDialog.data && openDialog.data.tripNum
                data.partyId = openDialog.data && openDialog.data.partyId
              data.userId = FileForUserGetCurrentLoginId()
                handleEmailCoverSheet(data)
                onClose()
            }
          }}
          formState={FormState.COMPLETED}
          renderBodySection={(labelMap, shouldHideMap) => (
            <FaxCoverSheetFormProperties.Fields
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
              tripNo={openDialog.data && openDialog.data.tripNum}
            />
          )}
          renderButtonSection={() => (
            <Box display='flex' justifyContent='flex-end'>
              <CngButton color='primary' size='medium' type='submit'>
                {translatedTextsObject.sendButton}
              </CngButton>
            </Box>
          )}
        />
      }
      dialogTitle={translatedTextsObject.faxTitle}
      // fullWidth
      // maxWidth='md'
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          onClose()
        }
      }}
      open={openDialog.open}
      shouldShowCloseButton
    />
  )
}

export default FaxCoverSheetDialog
