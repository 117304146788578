import { Box, Divider, Grid, IconButton, InputAdornment, Typography, useTheme } from '@material-ui/core'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import React, { useEffect, useState } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import { useFormContext, useWatch } from "react-hook-form";

import CollapsibleSection from '../../../components/ui/CollapsibleSection'
import EquipmentMasterApiUrls from "../../../apiUrls/EquipmentMasterApiUrls"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'
import { NaEquipmentMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { NaStateCodeAutoCompleteField } from 'src/components/na'
import Namespace from 'src/constants/locale/Namespace'
import TemplateSelectDialog from '../../../components/aciacehighway/TemplateSelectDialog'
import makeValidationSchema from './TrailerDTOMakeValidationSchema'
import { v4 as uuid } from 'uuid'

const {
  button: { CngButton },
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem,
} = components

const {
  filter: { LIKE, EQUAL }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  equipmentId: "",
  cargoLoadedIn: "TL",
  equipmentNumber: "",
  licensePlateNum: "",
  countryCode: "",
  saveTrailerFlag: false,
  contSealExCargoFlag: false,
  fetchTrailer: "",
  stateCode: "",
  container1: "",
  container2: "",
  container3: "",
  container4: "",
  container5: "",
  sealNo1: "",
  sealNo2: "",
  sealNo3: "",
  sealNo4: "",
  sealNo5: "",
  sealNo6: "",
  sealNo7: "",
  sealNo8: "",
  sealNo9: "",
  sealNo10: "",
  sealNo11: "",
  sealNo12: "",
  sealNo13: "",
  sealNo14: "",
  sealNo15: "",
  sealNo16: "",
  sealNo17: "",
  sealNo18: "",
  sealNo19: "",
  sealNo20: "",
  iit: "",
  lvs: "",
  postal: "",
  flyingTrucks: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { setValue, reset, watch } = useFormContext();
  const { translate } = useTranslation(Namespace.ACIHWY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()
  const [templateDialog, setTemplateDialog] = useState(false)
  const theme = useTheme()
  const saveTrailerFlag = watch('saveTrailerFlag')
  const countryCode = watch("countryCode");
  const cargoLoadedIn = watch("cargoLoadedIn");
  let ignoreEquipmentTypes = ['CL', 'CU', 'CZ', '20', '2B', '40', '4B'];

  const sealNos = watch([
    'sealNo1',
    'sealNo2',
    'sealNo3',
    'sealNo4',
    'sealNo5',
    'sealNo6',
    'sealNo7',
    'sealNo8',
    'sealNo9',
    'sealNo10',
    'sealNo11',
    'sealNo12',
    'sealNo13',
    'sealNo14',
    'sealNo15',
    'sealNo16',
    'sealNo17',
    'sealNo18',
    'sealNo19',
    'sealNo20'
  ])
  const containers = watch([
    'container1',
    'container2',
    'container3',
    'container4',
    'container5'
  ])
  const exceptionCargos = watch(['iit', 'lvs', 'postal', 'flyingTrucks'])

  const [stateKey, setStateKey] = useState(uuid())

  useEffect(() => {
    setStateKey(uuid())
  }, [countryCode])

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function makeTranslatedTextsObject() {
    let trailerDTO = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.TITLE
    )

    let containerTitle = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CONATINER_TITLE
    )

    let sealTitle = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_TITLE
    )

    let exceptioncargoTitle = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.EXCEPTION_CARGO
    )

    let equipmentId = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.EQUIPMENT_ID
    )
    let cargoLoadedIn = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CARGO_LOADED_IN
    )
    let saveTrailerFlag = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SAVE_TRAILER_FLAG
    )

    let fetchEquipment = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.FETCH_EQUIPMENT
    )

    let equipmentNumber = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.EQUIPMENT_NUMBER
    )
    let licensePlateNum = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.LICENSE_PLATE_NUM
    )
    let countryCode = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.COUNTRY
    )
    let stateCode = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.STATE_CODE
    )
    let contSealExCargoFlag = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CONT_SEAL_EXCARGOFLAG
    )
    let container1 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CONTAINER1
    )
    let container2 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CONTAINER2
    )
    let container3 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CONTAINER3
    )
    let container4 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CONTAINER4
    )
    let container5 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CONTAINER5
    )
    let sealNo1 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO1
    )
    let sealNo2 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO2
    )
    let sealNo3 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO3
    )
    let sealNo4 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO4
    )
    let sealNo5 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO5
    )
    let sealNo6 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO6
    )
    let sealNo7 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO7
    )
    let sealNo8 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO8
    )
    let sealNo9 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO9
    )
    let sealNo10 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO10
    )
    let sealNo11 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO11
    )
    let sealNo12 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO12
    )
    let sealNo13 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO13
    )
    let sealNo14 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO14
    )
    let sealNo15 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO15
    )
    let sealNo16 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO16
    )
    let sealNo17 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO17
    )
    let sealNo18 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO18
    )
    let sealNo19 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO19
    )
    let sealNo20 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO20
    )

    let iit = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.IIT
    )
    let lvs = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.LVS
    )
    let postal = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.POSTAL
    )
    let flyingTrucks = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.FLYING_TRUCKS
    )
    let sealNoHideText = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO_HIDE_TEXT
    )
    let sealNoShowText = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO_SHOW_TEXT
    )
    let sealNoFooterText = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO_FOOTER_TEXT
    )
    let exemptionCargoHideText = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.EXEMPTION_CARGO_HIDE_TEXT
    )
    let exemptionCargoShowText = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.EXEMPTION_CARGO_SHOW_TEXT
    )
    let exemptionCargoFooterText = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.EXEMPTION_CARGO_FOOTER_TEXT
    )
    let containerInfoHideText = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.CANTAINER_HIDE_TEXT
    )
    let containerInfoShowText = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.CANTAINER_SHOW_TEXT
    )
    let containerInfoFooterText = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.CANTAINER_FOOTER_TEXT
    )

    return {
      trailerDTO,
      containerTitle,
      sealTitle,
      exceptioncargoTitle,
      equipmentId,
      fetchEquipment,
      cargoLoadedIn,
      equipmentNumber,
      licensePlateNum,
      countryCode,
      stateCode,
      saveTrailerFlag,
      contSealExCargoFlag,
      container1,
      container2,
      container3,
      container4,
      container5,
      sealNo1,
      sealNo2,
      sealNo3,
      sealNo4,
      sealNo5,
      sealNo6,
      sealNo7,
      sealNo8,
      sealNo9,
      sealNo10,
      sealNo11,
      sealNo12,
      sealNo13,
      sealNo14,
      sealNo15,
      sealNo16,
      sealNo17,
      sealNo18,
      sealNo19,
      sealNo20,
      iit,
      lvs,
      postal,
      flyingTrucks,
      sealNoHideText,
      sealNoShowText,
      sealNoFooterText,
      exemptionCargoHideText,
      exemptionCargoShowText,
      exemptionCargoFooterText,
      containerInfoHideText,
      containerInfoShowText,
      containerInfoFooterText,
    }
  }

  const columns = [
    {
      field: 'equipmentId',
      title: translatedTextsObject.equipmentId
    },
    {
      field: 'equipmentNo',
      title: translatedTextsObject.equipmentNumber
    },
    {
      field: 'equipmentType',
      title: 'Equipment type'
    },
    {
      field: 'cargoLoadedIn',
      title: translatedTextsObject.cargoLoadedIn
    },
  ]

  function handleApplyTemplate(template) {
    if (template) {
      const { equipmentType, equipmentNo, licensePlates } = template

      if (equipmentType) {
        if (!ignoreEquipmentTypes.includes(equipmentType)) {
          setValue('cargoLoadedIn', equipmentType === 'CH' ? 'CH' : 'TL', { shouldValidate: true })
        } else {
          setValue('cargoLoadedIn', '', { shouldValidate: true })
        }
      }

      setValue('equipmentNumber', equipmentNo || '', { shouldValidate: true })

      if (licensePlates.length > 0) {
        const { licensePlateNo, state, countryofRegistration } = licensePlates[0]

        setValue('licensePlateNum', licensePlateNo || '', { shouldValidate: true })
        setValue('stateCode', state || '', { shouldValidate: true })
        setValue('countryCode', countryofRegistration || '', { shouldValidate: true })
      }
    } else {
      reset()
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} lg={4} />
            <CngGridItem xs={12} lg={8}>
              <Grid container spacing={1}>
                <CngGridItem xs={12} lg={4} shouldHide={shouldHideMap.saveTrailerFlag}>
                  <CngCheckboxField
                    label={
                      <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                        {translatedTextsObject.saveTrailerFlag}
                      </Typography>
                    }
                    name="saveTrailerFlag"
                    onChange={(e) =>
                      setValue('saveTrailerFlag', e.target.checked, { shouldValidate: true })
                    }
                    size='small'
                    style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm shouldHide={shouldHideMap.equipmentId}>
                  <CngTextField
                    name='equipmentId'
                    label={translatedTextsObject.equipmentId}
                    disabled={disabled || !saveTrailerFlag}
                    inputProps={{ maxLength: 15 }}
                    onChange={(e) => {
                      setValue('equipmentId', e.target.value.toUpperCase(), { shouldValidate: true })
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={4}>
                  <NaEquipmentMasterAutoCompleteField
                    name="fetchEquipment"
                    label={translatedTextsObject.fetchEquipment}
                    disabled={disabled}
                    onChange={(_, options) => handleApplyTemplate(options.data)}
                    size='small'
                    fullWidth
                    disableClearable
                    textFieldProps={{
                      InputProps: {
                        customEndAdornment: () => (
                          <InputAdornment position='end' style={{ marginTop: -16 }}>
                            <IconButton onClick={(event) => {
                              event.stopPropagation()
                              setTemplateDialog(true)
                            }}>
                              <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                                <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    }}
                    forcePopupIcon={false}
                    lookupProps={{
                      filters: [{
                        field: 'partyId',
                        operator: EQUAL,
                        value: FileForUserGetPartyId()
                      }]
                    }}
                  />
                </CngGridItem>
              </Grid>
            </CngGridItem>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.cargoLoadedIn}>
              <CngCodeMasterAutocompleteField
                required
                name="cargoLoadedIn"
                label={translatedTextsObject.cargoLoadedIn}
                disabled={disabled}
                codeType='ACIHWY_TRAILER_TYPE'
                key={cargoLoadedIn}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.equipmentNumber}>
              <CngTextField
                required
                name="equipmentNumber"
                label={translatedTextsObject.equipmentNumber}
                disabled={disabled}
                inputProps={{ maxLength: 15 }}
                onChange={(e) => setValue("equipmentNumber", e.target.value.toUpperCase(), { shouldValidate: true })}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.licensePlateNum}>
              <CngTextField
                required
                name="licensePlateNum"
                label={translatedTextsObject.licensePlateNum}
                disabled={disabled}
                inputProps={{ maxLength: 80 }}
                onChange={(e) => setValue("licensePlateNum", e.target.value.toUpperCase(), { shouldValidate: true })}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.countryCode}>
              <CngCodeMasterAutocompleteField
                required
                name="countryCode"
                label={translatedTextsObject.countryCode}
                disabled={disabled}
                codeType='ACIHWY_MANIFEST_COUNTRY'
                key={countryCode}
                onChange={() => setValue("stateCode", "", { shouldValidate: true })}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.stateCode}>
              <NaStateCodeAutoCompleteField
                required
                name="stateCode"
                label={translatedTextsObject.stateCode}
                disabled={disabled}
                countryCode={countryCode}
                size='small'
                key={stateKey}
              />
            </CngGridItem>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CollapsibleSection
            title={translatedTextsObject.containerTitle}
            defaultExpanded={containers.filter((container) => container).length > 0}
            footerText={translatedTextsObject.containerInfoFooterText}
            hideText={translatedTextsObject.containerInfoHideText}
            showText={translatedTextsObject.containerInfoShowText}
          >
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.container1}>
                <CngTextField
                  name="container1"
                  label={translatedTextsObject.container1}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("container1", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.container2}>
                <CngTextField
                  name="container2"
                  label={translatedTextsObject.container2}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("container2", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.container3}>
                <CngTextField
                  name="container3"
                  label={translatedTextsObject.container3}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("container3", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.container4}>
                <CngTextField
                  name="container4"
                  label={translatedTextsObject.container4}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("container4", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.container5}>
                <CngTextField
                  name="container5"
                  label={translatedTextsObject.container5}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("container5", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
            </Grid>
          </CollapsibleSection>
        </Grid>
        <Grid item xs={12}>
          <CollapsibleSection
            title={translatedTextsObject.sealTitle}
            defaultExpanded={sealNos.filter((sealNo) => sealNo).length > 0}
            footerText={translatedTextsObject.sealNoFooterText}
            hideText={translatedTextsObject.sealNoHideText}
            showText={translatedTextsObject.sealNoShowText}
          >
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo1}>
                <CngTextField
                  name="sealNo1"
                  label={translatedTextsObject.sealNo1}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo1", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo2}>
                <CngTextField
                  name="sealNo2"
                  label={translatedTextsObject.sealNo2}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo2", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo3}>
                <CngTextField
                  name="sealNo3"
                  label={translatedTextsObject.sealNo3}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo3", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo4}>
                <CngTextField
                  name="sealNo4"
                  label={translatedTextsObject.sealNo4}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo4", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo5}>
                <CngTextField
                  name="sealNo5"
                  label={translatedTextsObject.sealNo5}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo5", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo6}>
                <CngTextField
                  name="sealNo6"
                  label={translatedTextsObject.sealNo6}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo6", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo7}>
                <CngTextField
                  name="sealNo7"
                  label={translatedTextsObject.sealNo7}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo7", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo8}>
                <CngTextField
                  name="sealNo8"
                  label={translatedTextsObject.sealNo8}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo8", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo9}>
                <CngTextField
                  name="sealNo9"
                  label={translatedTextsObject.sealNo9}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo9", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo10}>
                <CngTextField
                  name="sealNo10"
                  label={translatedTextsObject.sealNo10}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo10", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo11}>
                <CngTextField
                  name="sealNo11"
                  label={translatedTextsObject.sealNo11}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo11", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo12}>
                <CngTextField
                  name="sealNo12"
                  label={translatedTextsObject.sealNo12}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo12", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo13}>
                <CngTextField
                  name="sealNo13"
                  label={translatedTextsObject.sealNo13}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo13", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo14}>
                <CngTextField
                  name="sealNo14"
                  label={translatedTextsObject.sealNo14}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo14", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo15}>
                <CngTextField
                  name="sealNo15"
                  label={translatedTextsObject.sealNo15}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo15", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo16}>
                <CngTextField
                  name="sealNo16"
                  label={translatedTextsObject.sealNo16}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo16", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo17}>
                <CngTextField
                  name="sealNo17"
                  label={translatedTextsObject.sealNo17}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo17", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo18}>
                <CngTextField
                  name="sealNo18"
                  label={translatedTextsObject.sealNo18}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo18", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo19}>
                <CngTextField
                  name="sealNo19"
                  label={translatedTextsObject.sealNo19}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo19", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.sealNo20}>
                <CngTextField
                  name="sealNo20"
                  label={translatedTextsObject.sealNo20}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("sealNo20", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
            </Grid>
          </CollapsibleSection>
        </Grid>
        <Grid item xs={12}>
          <CollapsibleSection
            title={translatedTextsObject.exceptioncargoTitle}
            defaultExpanded={exceptionCargos.filter((exceptionCargo) => exceptionCargo).length > 0}
            footerText={translatedTextsObject.exemptionCargoFooterText}
            hideText={translatedTextsObject.exemptionCargoHideText}
            showText={translatedTextsObject.exemptionCargoShowText}
          >
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={6} shouldHide={false}>
                <CngTextField
                  name="iit"
                  label={translatedTextsObject.iit}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("iit", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={false}>
                <CngTextField
                  name="lvs"
                  label={translatedTextsObject.lvs}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("lvs", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={false}>
                <CngTextField
                  name="postal"
                  label={translatedTextsObject.postal}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("postal", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={false}>
                <CngTextField
                  name="flyingTrucks"
                  label={translatedTextsObject.flyingTrucks}
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => setValue("flyingTrucks", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
            </Grid>
          </CollapsibleSection>
        </Grid>
      </Grid>
      <TemplateSelectDialog
        columns={columns}
        defaultSorts={[{ field: 'equipmentId', direction: 'ASC' }]}
        fetch={{ url: EquipmentMasterApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        onApplyTemplate={handleApplyTemplate}
        onClose={() => setTemplateDialog(false)}
        open={templateDialog}
        search={{ field: 'equipmentId', operator: LIKE }}
        title='Equipment template'
      />
    </>
  )
}

const TrailerDTOFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default TrailerDTOFormProperties
