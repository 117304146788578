import makeValidationSchema from './StatusNotificationMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React, { useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants
} from 'cng-web-lib'
import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls'
import NaAciHwAttachedCCNAutoComField from 'src/components/na/autocomplete/codemaintenance/NaAciHwAttachedCCNAutoComField'
import { useFormContext, useWatch } from "react-hook-form";
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'


const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngSelectField,
      CngLookupAutocompleteField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem,
} = components

const {
  filter: {EQUAL}
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  shipmentId:"",
  name: "",
  snType: "",
  shipmentControlNum: "",
  email: "",
  mobileNum: "",
  faxNum: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  manifestId
}) {
  const { setValue, getValues, reset } = useFormContext();
  const { translate } = useTranslation(Namespace.ACIHWY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  let manifestIden = getValues("shipmentId");
  console.log("manifestIDDDDD",manifestIden)

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function makeTranslatedTextsObject() {
    let statusNotification = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.StatusNotification.TITLE
    )
    let name = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.StatusNotification.NAME
    )
    let snType = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.StatusNotification.SN_TYPE
    )
    let shipmentControlNum = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.StatusNotification.SHIPMENT_CONTROL_NUM
    )
    let email = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.StatusNotification.EMAIL
    )
    let mobileNum = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.StatusNotification.MOBILE_NUM
    )
    let faxNum = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.StatusNotification.FAX_NUM
    )

    return {
      statusNotification,
      name,
      snType,
      shipmentControlNum,
      email,
      mobileNum,
      faxNum,
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.statusNotification} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.name}>
            <CngTextField
            required
              name="name"
              label={translatedTextsObject.name}
              disabled={disabled}
              onChange={(e) => {
                setValue("name", e.target.value.toUpperCase(), { shouldValidate: true })
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.snType}>
            <CngCodeMasterAutocompleteField
            required
              name="snType"
              label={translatedTextsObject.snType}
              disabled={disabled}
              codeType='ACIHW_STATUS_NOTIFI_TYPE'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.shipmentControlNum}>
             <CngLookupAutocompleteField
               name="shipmentControlNum"
               label={translatedTextsObject.shipmentControlNum}
               lookupProps={{
               filters: [{ field: 'manifestId', operator: EQUAL, value: manifestIden }],
               label: 'ccn',
               url:  AciHighwayCargoApiUrls.SN_CCN_SEARCH, 
               value: 'ccn'
             }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.email}>
            <CngTextField
            required
              name="email"
              label={translatedTextsObject.email}
              disabled={disabled}
              onChange={(e) => {
                setValue("email", e.target.value.toUpperCase(), { shouldValidate: true })
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.mobileNum}>
            <CngTextField
              name="mobileNum"
              label={translatedTextsObject.mobileNum}
              disabled={disabled}
              onChange={(e) => {
                setValue("mobileNum", e.target.value.toUpperCase(), { shouldValidate: true })
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.faxNum}>
            <CngTextField
              name="faxNum"
              label={translatedTextsObject.faxNum}
              disabled={disabled}
              onChange={(e) => {
                setValue("faxNum", e.target.value.toUpperCase(), { shouldValidate: true })
              }}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

const StatusNotificationFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default StatusNotificationFormProperties
