import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import pathMap from '../../../paths/pathMap'
import ResponseLog from './view/ResponseLog'
import { Box, Grid } from '@material-ui/core'
import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls'

const { button: { CngButton } } = components
const { filter: { EQUAL } } = constants

function ViewLogsHistory(props) {
  const { history, manifestId, onChangeStep, onSubmitManifest, step, tripNo } = props
  const { fetchRecords } = useServices()

  const [cargoEmpty, setCargoEmpty] = useState(true);

  useEffect(() => {
    Promise.all([
      fetchRecords.execute(
        AciHighwayCargoApiUrls.GET,
        { filters: [{ field: 'manifestId', operator: EQUAL, value: manifestId }] },
        (res) => res.content
      )
    ]).then(([cargo]) => {
      setCargoEmpty(cargo && cargo.length > 0 ? false : true);
    })
  }, []);

  return (
    <>
      <ResponseLog manifestId={manifestId} tripNum={tripNo}/>
      <Box marginTop={2}>
        <Grid container justify='space-between' spacing={3}>
          <Grid item xs={12} md='auto'>
            <CngButton
              color='secondary'
              onClick={() =>
                history.push(pathMap.ACE_HIGHWAY_MANIFEST_LIST_VIEW)
              }
              size='medium'
            >
              Discard
            </CngButton>
          </Grid>
          <Grid item xs={12} md='auto'>
            <Grid container spacing={2}>
              <Grid item xs='auto'>
                <CngButton color='secondary' onClick={() => onChangeStep(step - 1)} size='medium'>
                  Back
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton
                  color='primary'
                  onClick={() => cargoEmpty ? onChangeStep(step - 3) : onChangeStep(step - 2)}
                  size='medium'>
                  Go to Submit
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ViewLogsHistory
