import { Container, Grid, Paper } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import ManifestForwardViewForm from './ManifestForwardViewPage'
import Namespace from 'src/constants/locale/Namespace'
import { components, useTranslation } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import EhblHouseBillKeys from 'src/constants/locale/key/EhblHouseBill'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'

const { CngTabs } = components

function ViewPage({ history, loading, location, onSetLoading, showNotification }) {
  const { id } = useParams()
  const { state } = location
  const { translate } = useTranslation([Namespace.EHBL_HOUSE_BILL])
  const translatedTextsObject = makeTranslatedTextsObject()
  const houseBillData = useRef({})
  
  function makeTranslatedTextsObject() {
    let viewHousebill = translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.VIEW_HOUSE_BILL
    )
    let setStatusNotification= translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.SET_STATUS_NOTIFICATION
    )
    let housebillResponse= translate(
      Namespace.EHBL_HOUSE_BILL,
      EhblHouseBillKeys.RESPONSE_TITLE
    )

    return {
      viewHousebill,
      setStatusNotification,
      housebillResponse
    }
  }


  return (
    <ManifestForwardViewForm
    showNotification={showNotification}
    id={id}
    houseBillData={houseBillData}
  />
  );
}

export default withFormState(ViewPage)
