import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import { constants } from 'cng-web-lib'
import AciHighwayStatQryKeys from 'src/constants/locale/key/AciHighwayStatusQuery'


const { locale: { key: { CommonValidationMessageKeys } } } = constants

function makeValidationSchema(translate) {
  let onlyOneReq = translate(Namespace.ACI_HIGHWAY_STAT_QRY, AciHighwayStatQryKeys.ONLY_ONE_REQ)
  let required = 'Please enter either Cargo Control Number or Entry Number'
  let ccnRegexMessage = 'Please enter only alphanumeric(without spaces)'
  let entryNoRegexMessage = 'Only digits are allowed'

  return Yup.object({
    ccn: Yup.string().min(5, 'Min length required is 5').max(25, 'Max length allowed is 25').matches(/^[a-zA-Z0-9\-]+$/, ccnRegexMessage).test("ccn", required, function () {
      let entryNo = this.parent["entryNo"];
      let ccn = this.parent["ccn"];
      if (ccn || entryNo)
        return true;
      else
        return false;
    }).test("ccn", onlyOneReq, function () {
      let entryNo = this.parent["entryNo"];
      let ccn = this.parent["ccn"];
      if (ccn && entryNo)
        return false;
      else
        return true;
    }),
    entryNo: Yup.string().max(14, 'Max length allowed is 14').matches("^[0-9]*$", entryNoRegexMessage).test("entryNo", required, function () {
      let entryNo = this.parent["entryNo"];
      let ccn = this.parent["ccn"];
      if (ccn || entryNo)
        return true;
      else
        return false;
    }).test("entryNo", onlyOneReq, function () {
      let entryNo = this.parent["entryNo"];
      let ccn = this.parent["ccn"];
      if (ccn && entryNo)
        return false;
      else
        return true;
    }),
    warehouseCode: Yup.string().nullable(),
  })
}

export default makeValidationSchema
