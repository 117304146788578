import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {constants} from 'cng-web-lib'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'


const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  let aplhaNumericWithSplChars = translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.ALPHA_NUMERIC_WITH_SPLCHARS
  )
  let emailValid=translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.EMAIL_VALID
  )
  let numericRegx=translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.NUMERIC_REGX
  )
  let maxLength25=translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.MAX_LENGTH_25
  )
  let maxLength254=translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.MAX_LENGTH_254
  )

  return Yup.object({
    name: Yup.string().required(requiredMessage).matches("^[a-zA-Z0-9 ()_.,-]*$", aplhaNumericWithSplChars).nullable(),
    snType: Yup.string().required(requiredMessage).nullable(),
    shipmentControlNum: Yup.string().nullable(),
    email: Yup.string().trim().required(requiredMessage).email(emailValid).max(254, maxLength254).nullable(),
    mobileNum: Yup.string().trim().max(25, maxLength25).matches(/^\d+$/, numericRegx).nullable(),
  }) 
}

export default makeValidationSchema
