import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {constants} from 'cng-web-lib'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'


const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  let alphaNumericRegx = translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.ALPHA_NUMERIC_REGX
  )
  let alphaNumericSpaceRegx = translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.ALPHA_NUMERIC_SPACE_REGX
  )
  let numericRegx= translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.NUMERIC_REGX
  )
  let aplhaNumericWithSplChars= translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.ALPHA_NUMERIC_WITH_SPLCHARS
  )

  return Yup.object({
    saveTrailerFlag: Yup.boolean().nullable(),
    equipmentId: Yup.string().when('saveTrailerFlag',{
    is: true,
    then: Yup.string().required(requiredMessage)
    }).matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx).nullable(),
    cargoLoadedIn: Yup.string().required(requiredMessage).nullable(),
    equipmentNumber: Yup.string().required(requiredMessage).matches("^[a-zA-Z0-9 ()_.,-]*$", aplhaNumericWithSplChars).nullable(),
    licensePlateNum: Yup.string().required(requiredMessage).matches("^[a-zA-Z0-9]*$", alphaNumericRegx).nullable(),
    countryCode: Yup.string().required(requiredMessage).nullable(),
    stateCode: Yup.string().required(requiredMessage).nullable(),
    container1: Yup.string().matches("^[a-zA-Z0-9]*$", alphaNumericRegx).nullable(),
    container2: Yup.string().matches("^[a-zA-Z0-9]*$", alphaNumericRegx).nullable(),
    container3: Yup.string().matches("^[a-zA-Z0-9]*$", alphaNumericRegx).nullable(),
    container4: Yup.string().matches("^[a-zA-Z0-9]*$", alphaNumericRegx).nullable(),
    container5: Yup.string().matches("^[a-zA-Z0-9]*$", alphaNumericRegx).nullable(),
    sealNo1: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx), 
    sealNo2: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo3: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo4: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo5: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx), 
    sealNo6: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo7: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo8: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo9: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo10: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo11: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo12: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo13: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo14: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo15: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo16: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo17: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo18: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo19: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo20: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    iit: Yup.string().nullable().matches("^[0-9]*$",numericRegx),
    lvs: Yup.string().nullable().matches("^[0-9]*$", numericRegx),
    postal: Yup.string().nullable().matches("^[0-9]*$", numericRegx),
    flyingTrucks: Yup.string().nullable().matches("^[0-9]*$", numericRegx),
  })
}

export default makeValidationSchema
