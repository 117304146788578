import ManifestResponse from './ManifestResponse';
import CargoResponse from './CargoResponse';
import D4NoticeResponse from './D4NoticeResponse';
import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import CngSection from 'src/components/cngcomponents/CngSection';
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AciHighwayKeys from 'src/constants/locale/key/AciHighwayResponse'
import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls'

const {
  filter: { EQUAL }
} = constants

function ResponseMessage({ showNotification, id, isViewOnly = false, ccnValue, manifestId, cargoPage, manifestPage }) {
  const { translate } = useTranslation(Namespace.ACI_HIGHWAY_RESPONSE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { fetchRecords, securedSendRequest } = useServices()

  function makeTranslatedTextsObject() {
    let manifestResponseTitle = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.MANIFEST_RESPONSE
    )
    let cargoResponseTitle = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.CARGO_RESPONSE
    )
    let d4ResponseTitle = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.D4_RESPONSE
    )

    return {
      manifestResponseTitle,
      cargoResponseTitle,
      d4ResponseTitle
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.manifestResponseTitle}>
          <ManifestResponse
            showNotification={showNotification}
            id={id}
            isViewOnly={true}
            manifestId={manifestId}
            cargoPage={cargoPage}
          />
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.cargoResponseTitle}>
          <CargoResponse
            showNotification={showNotification}
            id={id}
            isViewOnly={true}
            manifestPage={manifestPage}
          />
        </CngSection>
      </Grid>
      {ccnValue !== undefined && (
        <Grid item xs={12}>
          <CngSection title={translatedTextsObject.d4ResponseTitle}>
            <D4NoticeResponse
              showNotification={showNotification}
              id={id}
              isViewOnly={true}
              ccnValue={ccnValue}
            />
          </CngSection>
        </Grid>
      )}
    </Grid>
  )
}

export default ResponseMessage;