import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {constants} from 'cng-web-lib'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'


const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let emailValid=translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.EMAIL_VALID
  )

  return Yup.object({
    email: Yup.string().trim().required(requiredMessage).email(emailValid).nullable(),
    subject: Yup.string().nullable(),
    message: Yup.string().nullable(),
  }) 
}

export default makeValidationSchema
