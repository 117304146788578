import React from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AciHighwayCargoKeys from 'src/constants/locale/key/AciHighwayCargo'
import AciHighwayManifestApiUrls from 'src/apiUrls/AciHighwayManifestApiUrls'
import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls'
import Table from '../../../../components/aciacehighway/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import axios from 'axios'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'

const {
  button: { CngButton },
  CngDialog
} = components

const {
  filter: { LIKE, EQUAL, IN, BETWEEN }
} = constants

function AttachCargoDialog(props) {
  const {
    columns,
    manifestId,
    open,
    onAttachCargo,
    onClose,
    showNotification
  } = props

  const { translate } = useTranslation(Namespace.ACI_HIGHWAY_CARGO)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let attachCargoSuccessMsg = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ATTACH_CARGO_SUCESS_MSG
    )
    let attach = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ATTACH
    )
    let attachSentRecord = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ATTACH_SENT
    )

    return {
      attachCargoSuccessMsg,
      attach,
      attachSentRecord,
    }
  }

  function handleSubmitSelectedCargo(rows) {
    const result = {
      manifestId,
      cargoId: rows.map((cargo) => cargo.id)
    }

    const instance = axios.create({
      withCredentials: false,
      headers: {
        Authorization:
          'Bearer ' + JSON.parse(localStorage.getItem('userToken')),
        'Content-Type': 'application/json'
      }
    })

    instance
      .post(AciHighwayManifestApiUrls.ATTACH, JSON.stringify(result))
      .then((res) => {
        const { errorMessages } = res.data

        if (res.status === 200 && !errorMessages) {
          showNotification('success', translatedTextsObject.attachCargoSuccessMsg)
          onAttachCargo()
        } else {
          showNotification('error', errorMessages)
        }

        onClose()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const filters = [
    {
      label: translatedTextsObject.ccn,
      type: 'textfield',
      name: 'ccn',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.shipperName,
      type: 'textfield',
      name: 'shipperName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.consigneeName,
      type: 'textfield',
      name: 'consigneeName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.status,
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          label: 'Draft',
          value: 'draft',
          filterValue: {
            value: '1005'
          }
        },
        {
          label: 'Accepted',
          value: 'accepted',
          filterValue: {
            value: '1000'
          }
        },
        {
          label: 'Rejected',
          value: 'rejected',
          filterValue: {
            value: '1001'
          }
        },
        {
          label: 'Cancellation Accepted',
          value: 'cancellation_accepted',
          filterValue: {
            value: '1003'
          }
        },
        {
          label: 'Cancellation Rejected',
          value: 'cancellation_rejected',
          filterValue: {
            value: '1004'
          }
        },
        {
          label: 'Modification Rejected',
          value: 'modification_rejected',
          filterValue: {
            value: '1007'
          }
        }
      ]
    },
    {
      label: translatedTextsObject.entryNo,
      type: 'textfield',
      name: 'entryNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.customsBroker,
      type: 'textfield',
      name: 'customsBroker',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    },
  ]

  return (
    <CngDialog
      dialogTitle='Attach cargo'
      dialogContent={
        <Table
          checkboxSelection
          columns={columns}
          compact
          fetch={{ url: AciHighwayCargoApiUrls.GET }}
          fetchFilters={[
            { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() },
            { field: 'status', operator: IN, value: ['1005', '1000', '1001', '1003', '1004', '1007'] },
            { field: 'manifestId', operator: EQUAL, value: 'null' }
          ]}
          filters={filters}
          selectActions={[
            {
              disabled: (rows) => {
                let shouldDisable = false

                rows.forEach((rowData) => {
                  if (
                    rowData.status === '1008' ||
                    rowData.status === '1006' ||
                    rowData.status === '1002'
                  ) {
                    shouldDisable = true
                  }
                })

                return shouldDisable
              },
              icon: <FontAwesomeIcon icon={['fal', 'link']} />,
              label: translatedTextsObject.attach,
              onClick: (rows) => handleSubmitSelectedCargo(rows),
              tooltip: (rows) => {
                let includesSentRecords = false

                rows.forEach((rowData) => {
                  if (
                    rowData.status === '1008' ||
                    rowData.status === '1006' ||
                    rowData.status === '1002'
                  ) {
                    includesSentRecords = true
                  }
                })

                return includesSentRecords
                  ? translatedTextsObject.attachSentRecord
                  : null
              }
            }
          ]}
          search={{ field: 'ccn', operator: LIKE }}
          showNotification={showNotification}
          sortConfig={{
            type: 'column',
            defaultDirection: 'DESC',
            defaultField: 'updatedDate'
          }}
        />
      }
      dialogAction={
        <CngButton color='secondary' onClick={onClose}>
          Discard
        </CngButton>
      }
      fullWidth
      maxWidth='lg'
      open={open}
      onClose={onClose}
      shouldShowCloseButton
    />
  )
}

export default AttachCargoDialog
