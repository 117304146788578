import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import PersonDTOFormProperties from './PersonDTOFormProperties'
import { FormProvider, useForm } from 'react-hook-form'
import useCustomYupValidationResolver from '../../../components/aciacehighway/hooks/useCustomYupValidationResolver'
import { Box, Card, Grid, Tooltip } from '@material-ui/core'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues, makeValidationSchema } =
  PersonDTOFormProperties.formikProps

function PersonDTOEntry(props) {
  const {
    onAddPersonDTO,
    onClonePersonDTO,
    onDeletePersonDTO,
    onEditPersonDTO,
    personDTO,
    showNotification
  } = props

  const { translate } = useTranslation(Namespace.ACIHWY_MANIFEST)
  const methods = useForm({
    defaultValues: personDTO || initialValues,
    resolver: useCustomYupValidationResolver(makeValidationSchema(translate))
  })

  async function onSubmit(data) {
    const result = await methods.trigger()

    if (result) {
      onEditPersonDTO(data)
      methods.reset(data)
    }
  }

  return (
    <Card variant='outlined'>
      <Box padding={2}>
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PersonDTOFormProperties.Fields showNotification={showNotification} />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify='space-between' spacing={2}>
                <Grid item xs='auto'>
                  <Grid container spacing={1}>
                    <Grid item xs='auto'>
                      <Tooltip placement='bottom' title='Add new'>
                        <span>
                          <CngIconButton
                            icon={['fal', 'plus']}
                            onClick={() => onAddPersonDTO(initialValues)}
                            size='small'
                            type='outlined'
                          />
                        </span>
                      </Tooltip>
                    </Grid>
                    <Grid item xs='auto'>
                      <Tooltip placement='bottom' title='Clone'>
                        <span>
                          <CngIconButton
                            icon={['fal', 'copy']}
                            onClick={() => onClonePersonDTO(personDTO)}
                            size='small'
                            type='outlined'
                          />
                        </span>
                      </Tooltip>
                    </Grid>
                    <Grid item xs='auto'>
                      <Tooltip placement='bottom' title='Delete'>
                        <span>
                          <CngIconButton
                            icon={['fal', 'trash']}
                            onClick={() => onDeletePersonDTO(personDTO)}
                            size='small'
                            type='outlined'
                          />
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                {methods.formState.isDirty && (
                  <Grid item xs='auto'>
                    <Grid container spacing={2}>
                      <Grid item xs='auto'>
                        <CngButton
                          color='secondary'
                          onClick={() => methods.reset()}
                          size='medium'
                        >
                          Reset
                        </CngButton>
                      </Grid>
                      <Grid item xs='auto'>
                        <CngButton
                          color='primary'
                          onClick={methods.handleSubmit(onSubmit)}
                          size='medium'
                        >
                          Save changes
                        </CngButton>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </Box>
    </Card>
  )
}

export default PersonDTOEntry
