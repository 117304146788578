import React, { useEffect } from 'react';
import { components, useServices } from 'cng-web-lib';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton';
import CngField from 'src/components/cngcomponents/CngField';
import CngSection from 'src/components/cngcomponents/CngSection';
import { Grid,Typography } from '@material-ui/core';

const { CngDialog } = components;

function ViewRelatedCCN({ openDialog, onClose, data }) {
  const { fetchRecords } = useServices();

  return (
    <CngDialog
      customDialogContent={
        <Paper>
          <Box m={2} mt={1}>
            <Grid item xs={12}>
              <CngSection title='Related Housebill Information'>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField >
                    {data && data.relatedHouseBillDoc.length > 0 ? (
                      data.relatedHouseBillDoc.map((doc, index) => (
                        <Typography key={index}>
                          {doc.referenceIdentifier}
                        </Typography>
                      ))
                    ) : (
                      <Typography>No related documents available.</Typography>
                    )}
                    </CngField>
                  </Grid>
                </Grid>
              </CngSection>
            </Grid>
          </Box>
        </Paper>
      }
      dialogTitle={
        <>
          <b>House bills</b>
          <CloseDialogIconButton onClick={onClose} />
        </>
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='md'
      open={openDialog} // Pass the open state
      onClose={onClose}
    />
  );
}

export default ViewRelatedCCN;