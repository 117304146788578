import makeValidationSchema from './FaxCoverSheetMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React, { useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'
import { Card, Grid } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import { useFormContext,useWatch } from "react-hook-form";
import Alert from '../../../components/Alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
    },
  },
  CngGridItem,
} = components

const {
  filter: {EQUAL}
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  fax: "",
  subject: "",
  message: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  shouldHideMap,
  tripNo
}) {
  const { setValue } = useFormContext();
  const { translate } = useTranslation(Namespace.ACIHWY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  useEffect(() => {
    setValue("subject", tripNo)
  }, [tripNo]);

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function makeTranslatedTextsObject() {
    let fax = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.FAX
    )

    let emailSubject = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.EMAIL_SUBJECT
    )

    let emailMessage = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.EMAIL_MESSAGE
    )
    let faxHelpText = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.FAX_HELP_TEXT
    )
    let faxRemark = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.FAX_REMARK
    )
    return { fax, emailSubject, emailMessage,faxHelpText,faxRemark }
  }

	return (
    <Card>
      <Grid container spacing={2}>
        <CngGridItem xs={12} sm={12} shouldHide={shouldHideMap.fax}>
          <CngTextField
            required
            name="fax"
            label={translatedTextsObject.fax}
            disabled={disabled}
            helperText={translatedTextsObject.faxHelpText}
            size='small'
            inputProps={{ maxLength: 11 }}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={12} shouldHide={shouldHideMap.emailSubject}>
          <CngTextField
            name="subject"
            label={translatedTextsObject.emailSubject}
            disabled={disabled}
            size='small'
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={12} shouldHide={shouldHideMap.emailMessage}>
          <CngTextField
            multiline
            rows={4}
            variant="outlined"
            inputProps={{ maxLength: 100 }}
            name="message"
            label={translatedTextsObject.emailMessage}
            disabled={disabled}
            onChange={(e) => { setValue('message', e.target.value.replace(/(\r\n|\r|\n)+/g, ' ')) }}
          />
        </CngGridItem>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Alert
          severity='warning'
          icon={<FontAwesomeIcon icon={['fal', 'info-circle']} />}
        >
          <strong>NOTE:</strong> {translatedTextsObject.faxRemark}
        </Alert>
      </Grid>
    </Card>
	)
}

const FaxCoverSheetFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default FaxCoverSheetFormProperties
