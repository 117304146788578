import AciHighwayResponseApiUrls from "src/apiUrls/AciHighwayResponseApiUrls";
import React, { useEffect, useState } from "react";
import {
  useServices,
  useTranslation,
  constants,
  components
} from 'cng-web-lib'
import AciHighwayKeys from 'src/constants/locale/key/AciHighwayResponse';
import Namespace from 'src/constants/locale/Namespace';
import moment from 'moment-timezone'
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core'
import clsx from 'clsx'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components


const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

const {
  filter: { EQUAL },
  CodeMaintenanceType
} = constants

function D4NoticeResponse({
  showNotification,
  id,
  isViewOnly = false,
  ccnValue
}) {
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const [data, setData] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { fetchRecords } = useServices()
  const { translate } = useTranslation(Namespace.ACI_HIGHWAY_RESPONSE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const classes = useStyles()
  const [lookups, setLookups] = useState(null)

  useEffect(() => {
    fetchRecords.execute(
      AciHighwayResponseApiUrls.D4_GET,
      {
        filters: [
          {
            field: 'cbsaRefNo',
            operator: 'equal',
            value: ccnValue
          },
          {
            field: 'noticeType',
            operator: 'equal',
            value: 'ME'
          }
        ]
      },
      (res) => {
        setData(res.content)
      }
    )

    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'ACI_HW_D4_NOTICE_REASON_CODE' }
      ])
    ]).then(([noticeReasonCode]) => {
      setLookups({ noticeReasonCode })
    })

  }, [])

  function makeTranslatedTextsObject() {
    let responseId = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.RESPONSE_ID
    )
    let noticeReasonCode = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.NOTICE_REASON_CODE
    )
    let cbsaRefNo = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.CBSA_REF_NO
    )
    let portOfClearance = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.PORT_OF_CLEARANCE
    )
    let portOfReport = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.PORT_OF_REPORT
    )
    let portOfArrival = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.PORT_OF_ARRIVAL
    )
    let portOfDestination = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.PORT_OF_DESTINATION
    )
    let receivedDate = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.RECEIVED_DATE
    )
    let printD4 = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.PRINT_D4
    )
    let cbsaResponseDateTime = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.CBSA_RESPONSE_DATE
    )

    

    return {
      responseId,
      noticeReasonCode,
      cbsaRefNo,
      portOfClearance,
      portOfReport,
      portOfArrival,
      portOfDestination,
      receivedDate,
      printD4,
      cbsaResponseDateTime,
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const columns = [
    {
      field: "cbsaRelatedRefNo",
      title: translatedTextsObject.responseId
    },
    {
      field: "noticeReasonCode",
      title: translatedTextsObject.noticeReasonCode,
      render: (rowData) => getLookupValue('noticeReasonCode', rowData.noticeReasonCode)
    },
    {
      field: "cbsaRefNo",
      title: translatedTextsObject.cbsaRefNo
    },
    {
      field: "portOfClearance",
      title: translatedTextsObject.portOfClearance
    },
    {
      field: "portOfReport",
      title: translatedTextsObject.portOfReport
    },
    {
      field: "portOfArrival",
      title: translatedTextsObject.portOfArrival
    },
    {
      field: "portOfDest",
      title: translatedTextsObject.portOfDestination
    },
    {
      title: translatedTextsObject.cbsaResponseDateTime,
      render: (data) =>
        data.cbsaProcessDateTime &&
        moment(data.cbsaProcessDateTime).tz("Canada/Eastern").format('D MMM YYYY HH:mm')
    },
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  return (
    <>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((d) => (
                  <TableRow key={d.id}>
                    {columns.map((column, index) => (
                      <TableCell key={index}>
                        {column.render ? column.render(d) : d[column.field]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={columns.length}>
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100, 500, 1000]}
        component='div'
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  )

}

export default D4NoticeResponse;
