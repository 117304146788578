import React from 'react'
import StatusBar from '../../../components/aciacehighway/StatusBar'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngSubSection from '../../../components/cngcomponents/CngSubSection'
import CngField from '../../../components/cngcomponents/CngField'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import TrailerDTOTable from './TrailerDTOTable'
import CargoTable from './view/CargoTable'
import ResponseLog from './view/ResponseLog'
import History from './view/History'
import moment from 'moment'
import { Box, Card, Divider, Grid, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ManifestViewContent(props) {
  const {
    data,
    getCountryStateLabel,
    getLookupValue,
    id,
    isReviewPage,
    translatedTextsObject
  } = props

  const theme = useTheme()

  function getPersonDocumentDTOValues() {
    return data.personDTO.map((personDTO) => personDTO.personDocumentDTO).flat()
  }

  function getSealNos() {
    const result = []

    // Loop 20 times because of API response's structure
    for (let i = 1; i <= 20; i++) {
      if (data[`sealNo${i}`]) {
        result.push(data[`sealNo${i}`])
      }
    }

    return result
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StatusBar status={data.status} />
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.aciHighwayManifest}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.carrierCode}>
                {data.carrierCode}
              </CngField>
            </Grid>
            <Grid item xs={12} md={8}>
              <CngField label={translatedTextsObject.tripNum}>
                {data.tripNum}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.expArrivalDate}>
                {data.expArrivalDate &&
                  moment(data.expArrivalDate).format('D MMM YYYY, HH:mm')}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.firstExpectedPort}>
                <Typography component='div' variant='inherit'>
                  {getLookupValue('firstExpectedPort', data.firstExpectedPort)}
                  <TinyChip label={data.firstExpectedPort} />
                </Typography>
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.timeZone}>
                {data.timeZone && (
                  <Typography component='div' variant='inherit'>
                    {getLookupValue('timeZone', data.timeZone)}
                    <TinyChip label={data.timeZone} />
                  </Typography>
                )}
              </CngField>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.trucktitle}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={translatedTextsObject.conveyanceType}>
                    <Typography component='div' variant='inherit'>
                      {getLookupValue('conveyanceType', data.conveyanceType)}
                      <TinyChip label={data.conveyanceType} />
                    </Typography>
                  </CngField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <CngField label={translatedTextsObject.conveyanceNum}>
                    {data.conveyanceNum}
                  </CngField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <CngField label={translatedTextsObject.email}>
                    {data.email}
                  </CngField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CngSubSection title={translatedTextsObject.licenseNum}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label='License plate no.'>
                      {data.licenseNum}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={translatedTextsObject.countryCode}>
                      <Typography component='div' variant='inherit'>
                        {getLookupValue('countries', data.countryCode)}
                        <TinyChip label={data.countryCode} />
                      </Typography>
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={translatedTextsObject.state}>
                      {getCountryStateLabel(data.countryCode, data.state)}
                    </CngField>
                  </Grid>
                </Grid>
              </CngSubSection>
            </Grid>
            {getSealNos().length > 0 && (
              <Grid item xs={12}>
                <CngSubSection title={translatedTextsObject.sealtitle}>
                  <Grid container spacing={2}>
                    {getSealNos().map((sealNo, index) => (
                      <Grid key={index} item xs={12} sm={6} md={3}>
                        <CngField
                          label={translatedTextsObject[`sealNo${index + 1}`]}
                        >
                          {sealNo}
                        </CngField>
                      </Grid>
                    ))}
                  </Grid>
                </CngSubSection>
              </Grid>
            )}
            <Grid item xs={12}>
              <CngSubSection title={translatedTextsObject.iit}>
                {data.iit || data.lvs || data.postal || data.flyingTrucks ? (
                  <Grid container spacing={2}>
                    {data.iit && (
                      <Grid item xs={12} sm={6}>
                        <Box display='flex' style={{ gap: 8 }}>
                          <Box
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            height={24}
                            width={24}
                          >
                            <FontAwesomeIcon
                              icon={['fal', 'check']}
                              color={theme.palette.primary.main}
                            />
                          </Box>
                          <Box display='flex' flexDirection='column'>
                            <CngField label={translatedTextsObject.iit}>
                              {data.iit}
                            </CngField>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {data.lvs && (
                      <Grid item xs={12} sm={6}>
                        <Box display='flex' style={{ gap: 8 }}>
                          <Box
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            height={24}
                            width={24}
                          >
                            <FontAwesomeIcon
                              icon={['fal', 'check']}
                              color={theme.palette.primary.main}
                            />
                          </Box>
                          <Box display='flex' flexDirection='column'>
                            <CngField label={translatedTextsObject.lvs}>
                              {data.lvs}
                            </CngField>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {data.postal && (
                      <Grid item xs={12} sm={6}>
                        <CngField label={translatedTextsObject.postal}>
                          {data.postal}
                        </CngField>
                      </Grid>
                    )}
                    {data.flyingTrucks && (
                      <Grid item xs={12} sm={6}>
                        <CngField label={translatedTextsObject.flyingTrucks}>
                          {data.flyingTrucks}
                        </CngField>
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                )}
              </CngSubSection>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection
          title={
            <Box display='flex' alignItems='baseline' style={{ gap: 4 }}>
              <Typography style={{ fontWeight: 600 }}>
                {translatedTextsObject.trailerDTO}
              </Typography>
              <Typography color='textSecondary' variant='caption'>
                {`/ ${data.trailerDTO.length} Entries`}
              </Typography>
            </Box>
          }
        >
          <TrailerDTOTable data={data.trailerDTO} />
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.personDTO}>
          {data.personDTO.length > 0 ? (
            <Grid container spacing={2}>
              {data.personDTO.map(person => (
                <Grid key={person.id || person._id} item xs={12}>
                  <Card variant='outlined' style={{ backgroundColor: theme.palette.grey[50] }}>
                    <Box padding={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box py={1}>
                            <Typography color='textSecondary' variant='body2'>
                              {person.fastCardFlag
                                ? 'With FAST Card'
                                : 'With travel document'}
                            </Typography>
                          </Box>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CngField label={getLookupValue('partyType', person.role)}>
                            {[person.firstName, person.middleName, person.lastName]
                              .filter((name) => name)
                              .join(' ')}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <Grid container spacing={2}>
                            {person.fastCardFlag && (
                              <Grid item xs={12} sm={6}>
                                <CngField label={translatedTextsObject.fastCardNum}>
                                  {person.fastCardNum}
                                </CngField>
                              </Grid>
                            )}
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.citizenShip}>
                                {person.citizenShip}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.dateOfBirth}>
                                {person.dateOfBirth
                                  ? moment(person.dateOfBirth).format('D MMM YYYY')
                                  : person.dateOfBirth}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.cellNum}>
                                {person.cellNum}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.personEmail}>
                                {person.personEmail}
                              </CngField>
                            </Grid>
                          </Grid>
                        </Grid>
                        {!person.fastCardFlag && (
                          <Grid item xs={12}>
                            <CngSubSection title={translatedTextsObject.personDocumentDTO}>
                              {person.personDocumentDTO.length > 0 ? (
                                <Grid container spacing={1}>
                                  {person.personDocumentDTO.map((document, index) => (
                                    <React.Fragment key={document.id || document._id}>
                                      <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                          <Grid item xs={12} sm={6} lg={3}>
                                            <CngField label={translatedTextsObject.documentType}>
                                              {getLookupValue('documentType', document.documentType)}
                                            </CngField>
                                          </Grid>
                                          <Grid item xs={12} sm={6} lg={3}>
                                            <CngField label={translatedTextsObject.documentNumber}>
                                              {document.documentNumber}
                                            </CngField>
                                          </Grid>
                                          <Grid item xs={12} sm={6} lg={3}>
                                            <CngField label={translatedTextsObject.issuingCountry}>
                                              {document.issuingCountry}
                                            </CngField>
                                          </Grid>
                                          <Grid item xs={12} sm={6} lg={3}>
                                            <CngField label={translatedTextsObject.expiryDate}>
                                              {document.expiryDate}
                                            </CngField>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      {person.personDocumentDTO.length !== index + 1 && (
                                        <Grid item xs={12}>
                                          <Divider />
                                        </Grid>
                                      )}
                                    </React.Fragment>
                                  ))}
                                </Grid>
                              ) : (
                                <Typography variant='body2' color='textSecondary'>
                                  No records to display
                                </Typography>
                              )}
                            </CngSubSection>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant='body2' color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection
          title={
            <Box display='flex' alignItems='baseline' style={{ gap: 4 }}>
              <Typography style={{ fontWeight: 600 }}>
                {translatedTextsObject.cargo}
              </Typography>
              <Typography color='textSecondary' variant='caption'>
                {`/ ${data.cargoInfo.length} Entries`}
              </Typography>
            </Box>
          }
        >
          <CargoTable
            data={data.cargoInfo}
            getLookupValue={getLookupValue}
            getCountryStateLabel={getCountryStateLabel}
            manifestId={data.id}
          />
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.statusNotificationTitle}>
          {data.statusNotificationInfo.length > 0 ? (
            <Grid container spacing={3}>
              {data.statusNotificationInfo.map((notification, index) => (
                <React.Fragment key={notification.id}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.statusNotificationName}>
                          {notification.name}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.statusNotificationEmail}>
                          {notification.email}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.statusNotificationSnType}>
                          {notification.snType}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.statusNotificationShipmentControlNum}>
                          {notification.shipmentControlNum}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.statusNotificationMobileNum}>
                          {notification.mobileNum}
                        </CngField>
                      </Grid>
                    </Grid>
                  </Grid>
                  {data.statusNotificationInfo.length !== index + 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          ) : (
            <Typography variant='body2' color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid>
      {!isReviewPage && (
        <>
          <Grid item xs={12}>
            <ResponseLog manifestId={id} tripNum={data.tripNum} />
          </Grid>
          <Grid item xs={12}>
            <History manifestId={id} />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default ManifestViewContent
