import AciHighwayStatQryApiUrls from 'src/apiUrls/AciHighwayStatusQueryApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useRef }  from 'react'
import { components, useTranslation } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import CustomButtonSection from '../../common/CustomButtonSection'
import pathMap from 'src/paths/pathMap'
import AciHighwayStatQryKeys from 'src/constants/locale/key/AciHighwayStatusQuery'
import Namespace from 'src/constants/locale/Namespace'

const {
  form: { CngEditForm , CngEditFormButtonSection}
} = components

function EditPage({ showNotification }) {
  const { id } = useParams()
  const serverDataRef = useRef()

  const { translate } = useTranslation(Namespace.ACI_HIGHWAY_STAT_QRY)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
   
    let submitButton = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.SUBMIT_BUTTON
    )
    return {
      submitButton
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: AciHighwayStatQryApiUrls.GET
          }}
          update={{
            url: AciHighwayStatQryApiUrls.PUT
          }}
          id={id}
          renderButtonSection={() => {
            return (
              <CngEditFormButtonSection>
                <CustomButtonSection buttonLabel={translatedTextsObject.submitButton} url={AciHighwayStatQryApiUrls.SUBMIT} isAdd={true} showNotification={showNotification} redirect={pathMap.ACI_HW_STAT_QRY_LIST_VIEW}>
                </CustomButtonSection>
              </CngEditFormButtonSection>
            )
          }}
        />
      </Grid>
    </Grid>
  )
}

export default EditPage
