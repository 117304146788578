import React, { useEffect, useState } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls'
import AciHighwayResponseApiUrls from 'src/apiUrls/AciHighwayResponseApiUrls'
import AciHighwayManifestKeys from 'src/constants/locale/key/AciHighwayManifest'
import AciHighwayKeys from 'src/constants/locale/key/AciHighwayResponse'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment-timezone'
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core'
import clsx from 'clsx'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

function ManifestResponse(props) {
  const { showNotification, id, isViewOnly = false, manifestId, cargoPage } = props

  const [data, setData] = useState([])
  const [lookups, setLookups] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { fetchRecord, fetchRecords } = useServices()
  const { translate } = useTranslation([Namespace.ACE_HIGHWAY_RESPONSE])
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const classes = useStyles()

  function getCargo() {
    return fetchRecord.execute(AciHighwayCargoApiUrls.GET, id, (res) => res.content[0])
  }

  function fetchManifestResponse(cargoManifestId) {
    return fetchRecords.execute(AciHighwayResponseApiUrls.GET,
      {
        filters: [
          {
            field: 'headerId',
            operator: 'equal',
            value: cargoManifestId ? cargoManifestId : (cargoPage ? manifestId : id)
          },
          {
            field: 'referenceIdentification',
            operator: 'in',
            value: ['TG', 'AQ-M', 'BD-M']
          },
          {
            field: 'docCode',
            operator: 'in',
            value: ['350', '824', '997']
          },
        ]
      }, (res) => res.content)
  }

  function showManifestResponse() {
    if (!cargoPage || (cargoPage && (manifestId != null && manifestId.length != 0))) {
      Promise.all([fetchManifestResponse()]).then(([respData]) => setData(respData))
    } else {
      Promise.all([getCargo()]).then(([cargo]) => {
        if (cargo != null && cargo.manifestId) {
          Promise.all([fetchManifestResponse(cargo.manifestId)]).then(([respData]) => setData(respData))
        }
      })
    }
  }

  useEffect(() => {
    showManifestResponse();
    Promise.all([
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'ACI_HW_SUBMISSION_TYPE'
          }
        ],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACIHWY_STATUS' }],
        undefined,
        'code'
      )
    ]).then(([submissionType, status]) => {
      setLookups({ submissionType, status })
    })
  }, [])

  function makeTranslatedTextsObject() {
    let title = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.CARGO_RESPONSE
    )

    let responseId = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.RESPONSE_ID
    )

    let responseDate = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.RESPONSE_DATE
    )

    let tripNo = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.TRIP_NO
    )

    let status = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.STATUS
    )

    let responseCode = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.RESPONSE_CODE
    )

    let responseText = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.RESPONSE_TEXT
    )

    let cbsaResponseDate = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.CBSA_RESPONSE_DATE
    )

    let submissionType = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.SUBMISSION_TYPE
    )

    let remarks = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.REMARKS
    )
    let errorCode = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.ERRORCODE
    )
    let submitId = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.SUBMIT_ID
    )
    let methodOfSubmission = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.METHOD
    )

    return {
      title,
      responseId,
      responseDate,
      tripNo,
      status,
      responseCode,
      responseText,
      cbsaResponseDate,
      submissionType,
      remarks,
      errorCode,
      submitId,
      methodOfSubmission
    }
  }

  const columns = [
    {
      field: 'batchId',
      title: translatedTextsObject.responseId
    },
    {
      field: 'receivedDate',
      title: translatedTextsObject.responseDate,
      render: (data) =>
        data.receivedDate &&
        moment(data.receivedDate).tz("Canada/Eastern").format('D MMM YYYY HH:mm')
    },
    {
      field: 'tripNo',
      title: translatedTextsObject.tripNo,
      render: (data) => data.tripNo
    },
    {
      field: 'errorCode',
      title: translatedTextsObject.responseCode,
      render: (data) => (
        <Grid container spacing={1}>
          {data.errorResponses.map((response, index) => (
            <Grid key={index} item xs={12}>
              <Typography variant='body2'>{response.errorCode}</Typography>
              {/* <Typography color='textSecondary' variant='caption'>
                {response.remarks}
              </Typography> */}
            </Grid>
          ))}
        </Grid>
      )
    },
    {
      field: 'responseText',
      title: translatedTextsObject.responseText,
      render: (data) => (
        <Grid container spacing={1}>
          {data.errorResponses.map((response, index) => (
            <Grid key={index} item xs={12}>
              <Typography variant='body2'>{response.responseText}</Typography>
            </Grid>
          ))}
        </Grid>
      )
    },
    {
      title: translatedTextsObject.submissionType,
      render: (data) =>
        lookups
          ? lookups['submissionType'][data.submissionType]
          : data.submissionType
    },
    {
      field: 'status',
      title: translatedTextsObject.status,
      render: (data) => (lookups ? lookups['status'][data.status] : data.status)
    },

    {
      title: translatedTextsObject.cbsaResponseDate,
      render: (data) =>
        data.cbsaProcessingDate &&
        moment(data.cbsaProcessingDate).tz("Canada/Eastern").format('D MMM YYYY HH:mm')
    },
    {
      field: 'submitId',
      title: translatedTextsObject.submitId,
      render: (data) => data.submitId
    },
    { field: 'methodOfSubmission',
    title: translatedTextsObject.methodOfSubmission,
    render: (data) => data.methodOfSubmission } 
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  return (
    <>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((d) => (
                  <TableRow key={d.id}>
                    {columns.map((column, index) => (
                      <TableCell key={index}>
                        {column.render ? column.render(d) : d[column.field]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={columns.length}>
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100, 500, 1000]}
        component='div'
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  )
}

export default ManifestResponse
