import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import { constants } from 'cng-web-lib'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'


const { locale: { key: { CommonValidationMessageKeys } } } = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  let nonFastcardUser = translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.NON_FAST_CARD_USER
  )
  let alphaNumericSpaceRegx = translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.ALPHA_NUMERIC_SPACE_REGX
  )
  let alphaNumericRegx = translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.ALPHA_NUMERIC_REGX
  )

  let aplhaNumericWithSplChars = translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.ALPHA_NUMERIC_WITH_SPLCHARS
  )

  let alphaNumericWithMoreSplChars = translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.ALPHA_NUMERIC_WITH_MORE_SPLCHARS
  )

  let emailValid=translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.EMAIL_VALID
  )
  let maxLength15=translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.MAX_LENGTH_15
  )
  let maxLength255=translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.MAX_LENGTH_255
  )
  let numericRegx=translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.NUMERIC_REGX
  )

  const regexAlphaNumericSpecChar = "^[a-zA-Z0-9!\"\',-./:;?=()_@./#&+\-,\\s]*$"

  return Yup.object({
    saveCrewFlag: Yup.boolean().nullable(),
    crewId: Yup.string().when('saveCrewFlag',{
      is: true,
      then: Yup.string().required(requiredMessage)
    }).matches(regexAlphaNumericSpecChar, alphaNumericWithMoreSplChars).nullable(),
    role: Yup.string().required(requiredMessage).nullable(),
    fastCard: Yup.boolean().nullable(),
    fastCardNum: Yup.string().when('fastCard',{
    is: true,
    then: Yup.string().required(requiredMessage)
    }).matches("^[a-zA-Z0-9]*$", alphaNumericRegx).nullable(),
    firstName: Yup.string().when('fastCard',{
      is: false,
      then: Yup.string().required(nonFastcardUser)
    }).matches(regexAlphaNumericSpecChar, alphaNumericWithMoreSplChars).nullable(),
    middleName: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericWithMoreSplChars).nullable(),
    lastName: Yup.string().when('fastCard',{
      is: false,
      then: Yup.string().required(nonFastcardUser)
    }).matches(regexAlphaNumericSpecChar, alphaNumericWithMoreSplChars).nullable(),
    citizenShip: Yup.string().when('fastCard',{
      is: false,
      then: Yup.string().required(requiredMessage)
      }).nullable(),
    dateOfBirth: Yup.date().when('fastCard',{
     is: false,
     then: Yup.date().validFormat().typeError(dateTypeErrorMessage).required(requiredMessage).nullable(),
    }).nullable(),
    personEmail: Yup.string().trim().email(emailValid).max(255, maxLength255).nullable(),
    cellNum: Yup.string().trim().max(15, maxLength15).matches(/^\d+$/, numericRegx).nullable(),
  })
}

export default makeValidationSchema
