import { Box, Card } from '@material-ui/core'
import React from 'react'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import Stepper from '../../../components/aciacehighway/Stepper'
import ManifestAddForm from './ManifestAddForm'

function AddPage({ history, showNotification, onSetLoading }) {
  return (
    <Card>
      <Stepper steps={['Create Trip']} activeStep={0} />
      <Box padding={2}>
        <ManifestAddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => console.log('submit success')}
          onSetLoading={onSetLoading}
        />
      </Box>
    </Card>
  )
}

export default withFormState(AddPage)
