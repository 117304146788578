import React, { useCallback, useEffect, useState } from 'react'
import { components } from 'cng-web-lib'
import { getStatusMetadata } from '../../../../common/NACommon'
import CngField from '../../../../components/cngcomponents/CngField'
import TinyChip from '../../../../components/aciacehighway/TinyChip'
import moment from 'moment'
import {
  Box,
  Collapse,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  Typography
} from '@material-ui/core'

const {
  button: { CngButton, CngIconButton },
  CngDialog
} = components

function SetResponseMessageDialog(props) {
  const {
    amendmentCodeOptions,
    onClose,
    onSaveCargo,
    onSubmitCargo,
    open,
    cargos,
    translatedTextsObject
  } = props

  const getStatusList = useCallback(() => {
    const result = []
    const statusList = [...new Set(cargos.map((cargo) => cargo.status))]

    statusList.forEach((status) => {
      result.push({
        data: { code: status, ...getStatusMetadata(status) },
        cargos: cargos.filter((cargo) => cargo.status === status)
      })
    })

    return result
  }, [cargos])

  return (
    <CngDialog
      dialogContent={
        <Grid container spacing={2}>
          {getStatusList().map((status, index) => (
            <Grid key={index} item xs={12}>
              <StatusItem
                amendmentCodeOptions={amendmentCodeOptions}
                index={index}
                status={status}
                translatedTextsObject={translatedTextsObject}
              />
            </Grid>
          ))}
        </Grid>
      }
      dialogAction={
        <Grid container justify='space-between' spacing={2}>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onClose} size='medium'>
              Back
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <Grid container justify='flex-end' spacing={2}>
              <Grid item xs='auto'>
                <CngButton
                  color='secondary'
                  onClick={() => onSaveCargo(cargos)}
                  size='medium'
                >
                  Save
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton
                  color='primary'
                  onClick={() => {
                    onSubmitCargo(cargos)
                    onClose()
                  }}
                  size='medium'
                >
                  Submit to customs
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      dialogTitle='Set response message'
      fullWidth
      maxWidth='md'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default SetResponseMessageDialog

function StatusItem(props) {
  const {
    amendmentCodeOptions,
    index,
    status: { data, cargos },
    translatedTextsObject
  } = props

  const [expanded, setExpanded] = useState(false)
  const [messageFunction, setMessageFunction] = useState('00')
  const [amendmentCode, setAmendmentCode] = useState(null)

  useEffect(() => {
    cargos.forEach((cargo) => {
      cargo.messageFunction = messageFunction
      cargo.amendmentCode = amendmentCode
    })
  }, [messageFunction, amendmentCode])

  return (
    <Paper variant='outlined'>
      <Box padding={2}>
        <Grid alignItems='center' container spacing={2}>
          <Grid item xs={12} md='auto'>
            <Typography variant='body1'>
              <Typography
                variant='inherit'
                style={{
                  color: data?.color || 'inherit',
                  fontWeight: 600
                }}
              >
                {data?.label}
              </Typography>
              &nbsp;
              <Typography variant='inherit'>({cargos.length})</Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md>
            <Grid container spacing={2}>
              <Grid item xs={12} md>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor={`message-function-label-${index}`}>
                    {translatedTextsObject.messageFunction}
                  </InputLabel>
                  <Select
                    label={translatedTextsObject.messageFunction}
                    inputProps={{
                      id: `message-function-label-${index}`
                    }}
                    native
                    onChange={(event) => setMessageFunction(event.target.value)}
                    size='small'
                    value={messageFunction}
                  >
                    <option value='00'>
                      {translatedTextsObject.msgFunctionAdd}
                    </option>
                    <option value='04'>
                      {translatedTextsObject.msgFunctionModify}
                    </option>
                    <option value='03'>
                      {translatedTextsObject.msgFunctionDelete}
                    </option>
                    <option value='CO'>
                      {translatedTextsObject.msgFunctionCorrected}
                    </option>
                  </Select>
                </FormControl>
              </Grid>
              {messageFunction === 'CO' && (
                <Grid item xs={12} md>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor={`amendment-code-label-${index}`}>
                      {translatedTextsObject.amendmentCode}
                    </InputLabel>
                    <Select
                      label={translatedTextsObject.amendmentCode}
                      inputProps={{
                        id: `amendment-code-label-${index}`
                      }}
                      native
                      onChange={(event) => setAmendmentCode(event.target.value)}
                      size='small'
                      value={amendmentCode}
                    >
                      {amendmentCodeOptions.map((option) => (
                        <option key={option.code} value={option.code}>
                          {option.desc}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md='auto'>
            <CngIconButton
              icon={['fal', expanded ? 'chevron-up' : 'chevron-down']}
              onClick={() => setExpanded((prev) => !prev)}
              size='small'
              type='outlined'
            />
          </Grid>
        </Grid>
        <Collapse in={expanded}>
          <Box marginTop={2}>
            <Grid container spacing={2}>
              {cargos.map((cargo, index) => (
                <React.Fragment key={index}>
                  <Grid key={index} item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Typography component='div' variant='inherit'>
                          {cargo.ccn}
                        </Typography>
                        <Typography color='textSecondary' variant='caption'>
                          {[cargo.cargoType, cargo.acquittalNo]
                            .filter((value) => value)
                            .join(' • ')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CngField label={`${translatedTextsObject.arrivalDateAndTime} & ${translatedTextsObject.arrivalDateAndTimeZone}`}>
                          <Typography component='div' variant='inherit'>
                            {cargo.arrivalDateAndTime &&
                              moment(cargo.arrivalDateAndTime).format('D MMM YYYY, HH:mm')}
                            {cargo.arrivalDateAndTimeZone && (
                              <TinyChip label={cargo.arrivalDateAndTimeZone} variant='outlined' />
                            )}
                          </Typography>
                        </CngField>
                      </Grid>
                    </Grid>
                  </Grid>
                  {cargos.length !== index + 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </Collapse>
      </Box>
    </Paper>
  )
}
