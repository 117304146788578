import React, { useEffect, useState } from 'react'
import { components } from 'cng-web-lib'
import { getStatusMetadata } from '../../../../common/NACommon'
import CngField from '../../../../components/cngcomponents/CngField'
import {
  Box,
  Collapse,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  Typography
} from '@material-ui/core'

const {
  button: { CngButton, CngIconButton },
  CngDialog
} = components

function SubmitAllCargoMessageDialog(props) {
  const {
    manifestId,
    onClose,
    onSubmitCargo,
    open,
    translatedTextsObject
  } = props

  const [submissionStatusType, setSubmissionStatusType] = useState('ALL')

  return (
    <CngDialog
      dialogContent={
        <Box paddingTop={1}>
          <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                      <StatusItem
                        submissionStatusType={submissionStatusType}
                        setSubmissionStatusType={setSubmissionStatusType}
                        translatedTextsObject={translatedTextsObject}
                      />
                  </Grid>
                </Grid>
              </Grid>
          </Grid>
        </Box>
      }
      dialogAction={
        <Grid container justify='space-between' spacing={2}>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onClose} size='medium'>
              Back
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <Grid container justify='flex-end' spacing={2}>
              <Grid item xs='auto'>
                <CngButton
                  color='primary'
                  onClick={() => {
                    onSubmitCargo({
                      manifestId,
                      status: submissionStatusType
                    })
                    onClose()
                  }}
                  size='medium'
                >
                  Submit to customs
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      dialogTitle='Submission Cargos Status'
      fullWidth
      maxWidth='md'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default SubmitAllCargoMessageDialog

function StatusItem(props) {
  const {
    submissionStatusType,
    setSubmissionStatusType,
    translatedTextsObject
  } = props

  function handleMessageFunctionChange(event) {
    setSubmissionStatusType(event.target.value)
  }

  return (
    <Paper variant='outlined'>
      <Box padding={2}>
        <Grid alignItems='center' container spacing={2}>
          <Grid item xs={12} md>
            <Grid container spacing={2}>
              <Grid item xs={12} md>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor={`message-function-label-${0}`}>
                    {'Submit for'}
                  </InputLabel>
                  <Select
                    label={translatedTextsObject.messageFunction}
                    inputProps={{
                      id: `message-function-label-${0}`
                    }}
                    native
                    onChange={handleMessageFunctionChange}
                    size='small'
                    value={submissionStatusType}
                  >
                    <option value='ALL'>
                      {"All"}
                    </option>
                    <option value='1005'>
                      {"Draft"}
                    </option>
                    <option value='1001'>
                      {"Rejected"}
                    </option>
                    <option value='1003'>
                      {"Cancelled"}
                    </option>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
