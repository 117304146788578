import AcihwManifestApiUrls from 'src/apiUrls/AciHighwayManifestApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import { useHistory } from 'react-router-dom'
import ConfirmDialog from '../../common/ConfirmDialog'
import { useFormContext } from 'react-hook-form'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import AciHighwayCargoApiUrls from '../../../apiUrls/AciHighwayCargoApiUrls'

const {
  button: { CngButton },
  form: { CngEditForm }
} = components

function ManifestEditPage(props) {
  const {
    id,
    manifestData,
    onChangeStep,
    onSetLoading,
    onSetManifestStatus,
    onSubmitManifest,
    showNotification,
    step,
    onCreateACEManifest
  } = props

  const onSuccessCallback = useRef(() => { })
  const [updateCargoPort, setUpdateCargoPort] = useState(false);
  const [selectedFirstExpectedPort, setSelectedFirstExpectedPort] = useState("")
  const [cargoIdList, setCargoIdList] = useState([])
  const { fetchRecords, securedSendRequest } = useServices()
  let manifestId = id;

  useEffect(() => {
    function onSuccess(response) {
      setCargoIdList(response.data)
    }

    function onError(error) {
      console.log(error);
    }

    function onComplete() {

    }
    const config = {}

    securedSendRequest.execute('POST', AciHighwayCargoApiUrls.GET_CARGO_BY_MANIFEST, { manifestId }, onSuccess, onError, onComplete, config, null);
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
              updateCargoPort={updateCargoPort}
              setSelectedFirstExpectedPort={setSelectedFirstExpectedPort}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: AcihwManifestApiUrls.GET,
            onPreSuccess: (serverData) => {
              serverData.isEditTemplate = serverData.templateFlag
              manifestData.current = serverData
              onSetManifestStatus(serverData.status)
            }
          }}
          update={{
            url: AcihwManifestApiUrls.PUT,
            onPostSubmitSuccess: (e) => {
              let fileForUserDetails = FileForUserGetUserDetails();
              if (fileForUserDetails != null && fileForUserDetails != undefined) {
                e.fileForUserId = fileForUserDetails.fileForUserId;
                e.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
                e.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
              }
              manifestData.current = e
              onSetManifestStatus(e.status)
              onSuccessCallback.current()
            }
          }}
          id={id}
          renderButtonSection={(_, loading) => (
            <ManifestEditPageButtonSection
              manifestId={id}
              showNotification={showNotification}
              onSaveOnly={() => {
                console.log("cargoIdList: " + cargoIdList.length);
                if (selectedFirstExpectedPort != manifestData.current.firstExpectedPort && cargoIdList.length > 0 && window.confirm('First Expected Port code is Changed, Do you want same changes to all attached cargo(s)?')) {
                  setUpdateCargoPort(true)
                }
                onSuccessCallback.current = (e) => {
                  onSetLoading(false)
                }
              }}
              onSaveAndContinue={() => {
                console.log("cargoIdList: " + cargoIdList.length);
                if (selectedFirstExpectedPort != manifestData.current.firstExpectedPort && cargoIdList.length > 0 && window.confirm('First Expected Port code is Changed, Do you want same changes to all attached cargo(s)?')) {
                  setUpdateCargoPort(true)
                }
                onSuccessCallback.current = () => {
                  onChangeStep(step + 1)
                  onSetLoading(false)
                }
              }}
              loading={loading}
              onSetLoading={onSetLoading}
              onSkip={() => onChangeStep(step + 1)}
              onSubmitManifest={(data) => {
                manifestData.current = data
                onSubmitManifest()
              }}
              onCreateACEManifest={(data) => {
                manifestData.current = data
                onCreateACEManifest()
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default ManifestEditPage

function ManifestEditPageButtonSection(props) {
  const {
    loading,
    onSaveOnly,
    onSaveAndContinue,
    onSetLoading,
    onSkip,
    onSubmitManifest,
    onCreateACEManifest
  } = props

  const [confirmDialog, setConfirmDialog] = useState(false)
  const { getValues, trigger } = useFormContext()
  const history = useHistory()

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={loading}
            onClick={() => setConfirmDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                onClick={async () => {
                  onCreateACEManifest(getValues())
                }}
                // type='submit'
                size='medium'
                shouldShowProgress={loading}
              >
                Create ACE Manifest
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                disabled={loading}
                onClick={onSkip}
                size='medium'
              >
                Skip
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                onClick={onSaveOnly}
                type='submit'
                size='medium'
                shouldShowProgress={loading}
              >
                Update
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                onClick={onSaveAndContinue}
                type='submit'
                size='medium'
                shouldShowProgress={loading}
              >
                Update &amp; continue
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                onClick={async () => {
                  const result = await trigger()
                  if (result) {
                    if (window.confirm('Please be advised you are sending an empty trip (with no shipments to CBSA). Do you want to proceed?')) {
                      onSubmitManifest(getValues())
                    }
                  }
                }}
                variant='contained'
                size='medium'
                shouldShowProgress={loading}
              >
                Submit manifest
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog}
        closeDialog={() => setConfirmDialog(false)}
        confirmDialog={() => history.push(pathMap.ACIHWY_MANIFEST_LIST_VIEW)}
        content="All progress in this session will be lost and cant't be restored. Are you sure about this?"
        okMsg='Yes, discard'
        cancelMsg='Continue editing'
        title='Discard'
      />
    </>
  )
}
