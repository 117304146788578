import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'
import AciHighwayCargoKeys from 'src/constants/locale/key/AciHighwayCargo'
import pathMap from 'src/paths/pathMap'
import AciHighwayManifestApiUrls from 'src/apiUrls/AciHighwayManifestApiUrls'
import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import StatusNotificationApiUrls from 'src/apiUrls/StatusNotificationApiUrls'
import ManifestViewContent from './ManifestViewContent'
import ConfirmDialog from '../../common/ConfirmDialog'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const {
  button: { CngButton },
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

function ViewPage(props) {
  const { history, showNotification } = props
  const [data, setData] = useState(null)
  const [lookups, setLookups] = useState(null)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const { id } = useParams()
  const { createRecord, deleteRecord, fetchRecord, fetchRecords } =
    useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.ACIHWY_MANIFEST)

  const translatedTextsObject = makeTranslatedTextsObject()

  useEffect(() => {
    Promise.all([
      // Data
      fetchRecord.execute(
        AciHighwayManifestApiUrls.GET,
        id,
        (res) => res.content[0]
      ),
      // Cargo
      fetchRecords.execute(
        AciHighwayCargoApiUrls.GET,
        {
          filters: [{ field: 'manifestId', operator: EQUAL, value: id }]
        },
        (res) => res.content
      ),
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACIHWY_STATUS' }],
        undefined,
        'code'
      ),
      // First expected port
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          customData: { codeMType: 'CustomsOffice' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      ),
      // Expected Time Zone
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'SCHEDULER_TIME_ZONE'
          }
        ],
        undefined,
        'code'
      ),
      // Conveyance type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'ACIHW_MANI_CONVEYANCE_TYPE'
          }
        ],
        undefined,
        'code'
      ),
      // Countries
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'ACIHWY_MANIFEST_COUNTRY'
          }
        ],
        undefined,
        'code'
      ),
      // Country states
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [{ field: 'indicator', operator: EQUAL, value: 'ACI' }],
          customData: { codeMType: 'CountryStateCode' }
        },
        (res) => res.content
      ),
      // Crew type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'HIGHWAY_CREW_TYPE' }],
        undefined,
        'code'
      ),
      // Party type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_PARTY_TYPE' }],
        undefined,
        'code'
      ),
      // Document type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACIHWY_DOCUMENT_TYPE' }],
        undefined,
        'code'
      ),
      //Status Notification
      fetchRecords.execute(
        StatusNotificationApiUrls.GET,
        {
          data: { shipmentId: id }
        },
        (res) => res.content
      ),
      // Arrival date time zone
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'SCHEDULER_TIME_ZONE' }],
        undefined,
        'code'
      )
    ]).then(
      ([
        data,
        cargoInfo,
        status,
        firstExpectedPort,
        timeZone,
        conveyanceType,
        countries,
        countryStates,
        crewType,
        partyType,
        documentType,
        statusNotificationInfo,
        arrivalDateAndTimeZone
      ]) => {
        if (data === undefined) {
          showNotification('error', 'Data not found.')
          history.push(pathMap.ACIHWY_MANIFEST_LIST_VIEW)
        }

        const states = Object.keys(countries).reduce((acc, countryCode) => {
          acc[countryCode] = countryStates.filter(
            (state) => state.code === countryCode
          )

          return acc
        }, {})

        setData({ ...data, cargoInfo, statusNotificationInfo })
        setLookups({
          status,
          firstExpectedPort,
          timeZone,
          conveyanceType,
          countries,
          countryStates: states,
          crewType,
          partyType,
          documentType,
          arrivalDateAndTimeZone
        })
      }
    )
  }, [])

  if (data === null) {
    return <CircularProgress />
  }

  function makeTranslatedTextsObject() {
    let aciHighwayManifest = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TITLE
    )
    let carrierCode = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SCAC)
    let tripNum = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.TRIP_NUM)
    let expArrivalDate = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.EXP_ARRIVAL_DATE
    )
    let firstExpectedPort = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.FIRST_EXPECTED_PORT
    )
    let timeZone = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.TIMEZONE)
    let trucktitle = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TRUCKTITLE
    )
    let conveyanceType = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.CONVEYANCE_TYPE
    )
    let conveyanceNum = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.CONVEYANCE_NUM
    )
    let licenseNum = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.LICENSE_NUM
    )
    let countryCode = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.COUNTRY_CODE
    )
    let state = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.STATE)
    let email = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.EMAIL)
    let sealtitle = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_TITLE
    )
    let sealNo1 = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SEAL_NO1)
    let sealNo2 = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SEAL_NO2)
    let sealNo3 = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SEAL_NO3)
    let sealNo4 = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SEAL_NO4)
    let sealNo5 = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SEAL_NO5)
    let sealNo6 = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SEAL_NO6)
    let sealNo7 = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SEAL_NO7)
    let sealNo8 = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SEAL_NO8)
    let sealNo9 = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SEAL_NO9)
    let sealNo10 = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SEAL_NO10)
    let sealNo11 = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SEAL_NO11)
    let sealNo12 = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SEAL_NO12)
    let sealNo13 = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SEAL_NO13)
    let sealNo14 = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SEAL_NO14)
    let sealNo15 = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SEAL_NO15)
    let sealNo16 = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SEAL_NO16)
    let sealNo17 = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SEAL_NO17)
    let sealNo18 = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SEAL_NO18)
    let sealNo19 = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SEAL_NO19)
    let sealNo20 = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.SEAL_NO20)
    let iit = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.IIT)
    let lvs = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.LVS)
    let postal = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.POSTAL)
    let flyingTrucks = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.FLYING_TRUCKS
    )
    let trailerDTO = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.TITLE
    )
    let cargoLoadedIn = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CARGO_LOADED_IN
    )
    let equipmentNumber = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.EQUIPMENT_NUMBER
    )
    let licensePlateNum = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.LICENSE_PLATE_NUM
    )
    let trailerDTOCountry = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.COUNTRY
    )
    let trailerDTOState = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.STATE_CODE
    )
    let personDTO = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.TITLE
    )
    let crewId = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.CREW_ID
    )
    let role = translate(Namespace.ACIHWY_MANIFEST, ManifestKeys.PersonDTO.ROLE)
    let fastCard = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.FAST_CARD
    )
    let fastCardNum = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.FAST_CARD_NUM
    )
    let firstName = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.FIRST_NAME
    )
    let middleName = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.MIDDLE_NAME
    )
    let lastName = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.LAST_NAME
    )
    let citizenShip = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.CITIZEN_SHIP
    )
    let dateOfBirth = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.DATE_OF_BIRTH
    )
    let personEmail = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.PERSON_EMAIL
    )
    let cellNum = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.CELL_NUM
    )
    let personDocumentDTO = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDocumentDTO.TITLE
    )
    let documentType = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDocumentDTO.DOCUMENT_TYPE
    )
    let issuingCountry = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDocumentDTO.ISSUING_COUNTRY
    )
    let documentNumber = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDocumentDTO.DOCUMENT_NUMBER
    )
    let expiryDate = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDocumentDTO.EXPIRY_DATE
    )
    let cargo = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.TITLE
    )
    let arrivalDateAndTime = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ARRIVAL_DATE_AND_TIME
    )
    let arrivalDateAndTimeZone = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ARRIVAL_DATE_AND_TIME_ZONE
    )
    let createdDateTime = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CREATIONDATETIME
    )
    let statusNotificationTitle = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.StatusNotification.TITLE
    )
    let statusNotificationName = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.StatusNotification.NAME
    )
    let statusNotificationEmail = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.StatusNotification.EMAIL
    )
    let statusNotificationSnType = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.StatusNotification.SN_TYPE
    )
    let statusNotificationShipmentControlNum = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.StatusNotification.SHIPMENT_CONTROL_NUM
    )
    let statusNotificationMobileNum = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.StatusNotification.MOBILE_NUM
    )
    let editButton = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.EDIT_BUTTON
    )
    let cloneButton = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.CLONE_BUTTON
    )
    let deleteButton = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.DELETE_BUTTON
    )

    return {
      aciHighwayManifest,
      carrierCode,
      tripNum,
      expArrivalDate,
      timeZone,
      firstExpectedPort,
      trucktitle,
      conveyanceType,
      conveyanceNum,
      email,
      licenseNum,
      countryCode,
      state,
      sealtitle,
      sealNo1,
      sealNo2,
      sealNo3,
      sealNo4,
      sealNo5,
      sealNo6,
      sealNo7,
      sealNo8,
      sealNo9,
      sealNo10,
      sealNo11,
      sealNo12,
      sealNo13,
      sealNo14,
      sealNo15,
      sealNo16,
      sealNo17,
      sealNo18,
      sealNo19,
      sealNo20,
      iit,
      lvs,
      postal,
      flyingTrucks,
      trailerDTO,
      cargoLoadedIn,
      equipmentNumber,
      licensePlateNum,
      trailerDTOCountry,
      trailerDTOState,
      personDTO,
      firstName,
      middleName,
      lastName,
      crewId,
      role,
      fastCard,
      fastCardNum,
      citizenShip,
      dateOfBirth,
      personEmail,
      cellNum,
      personDocumentDTO,
      documentType,
      issuingCountry,
      documentNumber,
      expiryDate,
      cargo,
      arrivalDateAndTime,
      arrivalDateAndTimeZone,
      createdDateTime,
      statusNotificationTitle,
      statusNotificationName,
      statusNotificationEmail,
      statusNotificationSnType,
      statusNotificationShipmentControlNum,
      statusNotificationMobileNum,
      editButton,
      cloneButton,
      deleteButton
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  function handleCloneManifest() {
    createRecord.execute(
      AciHighwayManifestApiUrls.CLONE,
      data,
      (data) => {
        history.push(pathMap.ACIHWY_MANIFEST_ADD_VIEW, data)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function handleDeleteManifest() {
    deleteRecord.execute(
      AciHighwayManifestApiUrls.DELETE,
      data,
      () => {
        showNotification('success', 'Delete succeeded')
        history.push(pathMap.ACIHWY_MANIFEST_LIST_VIEW)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  return (
    <>
      <Card>
        <CardContent>
          <ManifestViewContent
            data={data}
            getCountryStateLabel={getCountryStateLabel}
            getLookupValue={getLookupValue}
            id={id}
            translatedTextsObject={translatedTextsObject}
          />
        </CardContent>
        <Divider />
        <CardActions style={{ padding: 16, justifyContent: 'space-between' }}>
          <CngButton
            color='secondary'
            onClick={() => history.push(pathMap.ACIHWY_MANIFEST_LIST_VIEW)}
          >
            Back to manifest list
          </CngButton>
          <Box display='flex' style={{ gap: 16 }}>
            {data.status === '1005' && (
              <CngButton
                disabled={data.status !== '1005'}
                onClick={() => setConfirmDialogOpen(true)}
                startIcon={
                  <FontAwesomeIcon icon={['fal', 'trash']} size='sm' />
                }
                variant='outlined'
              >
                {translatedTextsObject.deleteButton}
              </CngButton>
            )}
            <CngButton
              onClick={handleCloneManifest}
              startIcon={<FontAwesomeIcon icon={['fal', 'copy']} size='sm' />}
              variant='outlined'
            >
              {translatedTextsObject.cloneButton}
            </CngButton>
            {data.status !== '1008' && (
              <CngButton
                color='primary'
                disabled={data.status === '1008'}
                startIcon={<FontAwesomeIcon icon={['fal', 'pen']} size='sm' />}
                onClick={() =>
                  history.push(`${pathMap.ACIHWY_MANIFEST}/edit/${id}`)
                }
              >
                {translatedTextsObject.editButton}
              </CngButton>
            )}
          </Box>
        </CardActions>
      </Card>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={handleDeleteManifest}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
    </>
  )
}

export default ViewPage
