import React, { useEffect, useState } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import TrailerDTODialog from './TrailerDTODialog'
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

const {
  button: { CngButton, CngIconButton },
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

function TrailerDTOTable(props) {
  const {
    data,
    onAddTrailerDTO,
    onDeleteTrailerDTO,
    onEditTrailerDTO,
    showNotification
  } = props

  const [lookups, setLookups] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [trailerDTODialog, setTrailerDTODialog] = useState({
    isView: false,
    open: false,
    trailerDTO: null
  })
  const { fetchRecords } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.ACIHWY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()
  const classes = useStyles()

  const filtered =
    searchTerm !== ''
      ? data.filter((trailerDTO) =>
          trailerDTO.equipmentNumber
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      : data

  useEffect(() => {
    Promise.all([
      // Cargo loaded in
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACIHWY_TRAILER_TYPE' }],
        undefined,
        'code'
      ),
      // Countries
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'ACIHWY_MANIFEST_COUNTRY'
          }
        ],
        undefined,
        'code'
      ),
      // Country states
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [{ field: 'indicator', operator: EQUAL, value: 'ACI' }],
          customData: { codeMType: 'CountryStateCode' }
        },
        (res) => res.content
      )
    ]).then(
      ([
        cargoLoadedIn,
        countries,
        countryStates
      ]) => {
        const states = Object.keys(countries).reduce((acc, countryCode) => {
          acc[countryCode] = countryStates.filter(
            (state) => state.code === countryCode
          )

          return acc
        }, {})

        setLookups({
          cargoLoadedIn,
          countries,
          countryStates: states
        })
      }
    )
  }, [])

  function makeTranslatedTextsObject() {
    let cargoLoadedIn = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CARGO_LOADED_IN
    )
    let equipmentNumber = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.EQUIPMENT_NUMBER
    )
    let licensePlateNum = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.LICENSE_PLATE_NUM
    )
    let trailerDTOTitle = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.TITLE
    )
    let country = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.COUNTRY
    )
    let state = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.STATE_CODE
    )
    let container1 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CONTAINER1
    )
    let container2 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CONTAINER2
    )
    let container3 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CONTAINER3
    )
    let container4 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CONTAINER4
    )
    let container5 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CONTAINER5
    )
    let sealNo1 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO1
    )
    let sealNo2 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO2
    )
    let sealNo3 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO3
    )
    let sealNo4 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO4
    )
    let sealNo5 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO5
    )
    let sealNo6 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO6
    )
    let sealNo7 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO7
    )
    let sealNo8 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO8
    )
    let sealNo9 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO9
    )
    let sealNo10 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO10
    )
    let sealNo11 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO11
    )
    let sealNo12 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO12
    )
    let sealNo13 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO13
    )
    let sealNo14 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO14
    )
    let sealNo15 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO15
    )
    let sealNo16 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO16
    )
    let sealNo17 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO17
    )
    let sealNo18 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO18
    )
    let sealNo19 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO19
    )
    let sealNo20 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.SEAL_NO20
    )
    let iit = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.IIT
    )
    let lvs = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.LVS
    )
    let postal = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.POSTAL
    )
    let flyingTrucks = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.FLYING_TRUCKS
    )
    let saveButton = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SAVE_BUTTON
    )
    let updateButton = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.UPDATE_BUTTON
    )

    return {
      cargoLoadedIn,
      equipmentNumber,
      licensePlateNum,
      trailerDTOTitle,
      country,
      state,
      container1,
      container2,
      container3,
      container4,
      container5,
      sealNo1,
      sealNo2,
      sealNo3,
      sealNo4,
      sealNo5,
      sealNo6,
      sealNo7,
      sealNo8,
      sealNo9,
      sealNo10,
      sealNo11,
      sealNo12,
      sealNo13,
      sealNo14,
      sealNo15,
      sealNo16,
      sealNo17,
      sealNo18,
      sealNo19,
      sealNo20,
      iit,
      lvs,
      postal,
      flyingTrucks,
      saveButton,
      updateButton
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  return (
    <>
      <Box marginBottom={1}>
        <Grid container spacing={2}>
          <Grid item xs>
            {data.length > 0 && (
              <TextField
                fullWidth
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder='Search in your entries'
                size='small'
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <FontAwesomeIcon icon={['fal', 'search']} />
                    </InputAdornment>
                  )
                }}
              />
            )}
          </Grid>
          {onAddTrailerDTO && (
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                onClick={() =>
                  setTrailerDTODialog({
                    open: true,
                    isView: false,
                    trailerDTO: null
                  })
                }
                size='medium'
                startIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
              >
                Add equipment
              </CngButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              <TableCell>{translatedTextsObject.equipmentNumber}</TableCell>
              <TableCell>{translatedTextsObject.cargoLoadedIn}</TableCell>
              <TableCell>{translatedTextsObject.licensePlateNum}</TableCell>
              {(onEditTrailerDTO || onDeleteTrailerDTO) && (
                <TableCell align='right'>Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered.length > 0 ? (
              filtered.map((trailerDTO) => (
                <TableRow
                  key={Math.random()}
                  onClick={() =>
                    setTrailerDTODialog({ open: true, isView: true, trailerDTO })
                  }
                >
                  <TableCell>{trailerDTO.equipmentNumber}</TableCell>
                  <TableCell>
                    {getLookupValue('cargoLoadedIn', trailerDTO.cargoLoadedIn)}
                  </TableCell>
                  <TableCell>{trailerDTO.licensePlateNum}</TableCell>
                  {(onEditTrailerDTO || onDeleteTrailerDTO) && (
                    <TableCell align='right'>
                      <Grid container justify='flex-end' spacing={1}>
                        {onEditTrailerDTO && (
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'pen']}
                              onClick={(event) => {
                                event.stopPropagation()
                                setTrailerDTODialog({ open: true, isView: false, trailerDTO })
                              }}
                              size='small'
                            />
                          </Grid>
                        )}
                        {onDeleteTrailerDTO && (
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'trash']}
                              onClick={(event) => {
                                event.stopPropagation()
                                onDeleteTrailerDTO(trailerDTO)
                              }}
                              size='small'
                            />
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={3 + (onEditTrailerDTO || onDeleteTrailerDTO ? 1 : 0)}
                >
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TrailerDTODialog
        getLookupValue={getLookupValue}
        getCountryStateLabel={getCountryStateLabel}
        isView={trailerDTODialog.isView}
        open={trailerDTODialog.open}
        onClose={() =>
          setTrailerDTODialog({ open: false, isView: false, trailerDTO: null })
        }
        onAddTrailerDTO={onAddTrailerDTO}
        onEditTrailerDTO={onEditTrailerDTO}
        showNotification={showNotification}
        trailerDTO={trailerDTODialog.trailerDTO}
        translatedTextsObject={translatedTextsObject}
      />
    </>
  )
}

export default TrailerDTOTable
