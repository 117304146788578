import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    id: Yup.string(),
    partyId: Yup.string(),
    headerId: Yup.string(),
    entryNumber: Yup.string(),
    receivedDate: Yup.string(),
    keyType: Yup.string(),
    serviceOptionId: Yup.string(),
    customsOfficeCode: Yup.string(),
    warehouseCode: Yup.string(),
    cbsaProcessingDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    releaseDate: Yup.string(),
    processingInd: Yup.string(),
    deliveryInst1: Yup.string(),
    deliveryInst2: Yup.string(),
    rejectComments1: Yup.string(),
    rejectComments2: Yup.string(),
    ccn: Yup.string(),
    senderMsgRefNo: Yup.string(),
    rejectType: Yup.string(),
    appErrorCode: Yup.string(),
    createdBy: Yup.string(),
    createdDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    updatedBy: Yup.string(),
    updatedDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    version: Yup.string(),
    containers: Yup.array(),
  })
}

export default makeValidationSchema
