import makeValidationSchema from './PersonDTOMakeValidationSchema'
import { components, constants, useTranslation } from 'cng-web-lib'
import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'
import CrewMasterApiUrls from "../../../apiUrls/CrewMasterApiUrls"
import { Box, Divider, Grid, IconButton, InputAdornment, Typography, useTheme } from '@material-ui/core'
import { useFormContext, useWatch } from "react-hook-form";
import moment from 'moment'
import Switch from 'src/components/Switch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TemplateSelectDialog from '../../../components/aciacehighway/TemplateSelectDialog'
import PersonDocumentDTOEntry from './PersonDocumentDTOEntry'
import CngSection from 'src/components/cngcomponents/CngSection'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import PersonDocumentDTOFormProperties from './PersonDocumentDTOFormProperties'
import { NaCrewMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import { AciAceCrewTypeMapping } from 'src/common/AciAceCrossMapping'

const {
  button: { CngButton },
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngDateField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField
    },
  },
  CngGridItem,
} = components

const {
  filter: { LIKE, EQUAL }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  crewId: "",
  role: "",
  fastCard: false,
  saveCrewFlag: false,
  fetchCrew:"",
  fastCardNum: "",
  firstName: "",
  middleName: "",
  lastName: "",
  citizenShip: "",
  dateOfBirth: "",
  personEmail: "",
  cellNum: "",
  personDocumentDTO: []
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}


function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { getValues, setValue, reset, trigger, watch } = useFormContext();
  const { translate } = useTranslation(Namespace.ACIHWY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()
  const [templateDialog, setTemplateDialog] = useState(false)
  const theme = useTheme()
  const { role, fastCard, saveCrewFlag, citizenShip, _id: _personDTOId, personDocumentDTO } = useWatch()

  useEffect(() => {
    if (personDocumentDTO.length === 0) {
      const { initialValues } = PersonDocumentDTOFormProperties.formikProps
      setValue('personDocumentDTO', [{ ...initialValues, _personDTOId, _id: 1 }])
    }
  }, [personDocumentDTO])

  function makeTranslatedTextsObject() {
    let personDTO = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.TITLE
    )
    let crewId = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.CREW_ID
    )
    let fetchCrew = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.FETCH_CREW
    )
    let role = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.ROLE
    )
    let saveCrewFlag = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.SAVE_CREW_FLAG
    )
    let fastCard = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.FAST_CARD
    )
    let fastCardNum = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.FAST_CARD_NUM
    )
    let firstName = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.FIRST_NAME
    )
    let middleName = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.MIDDLE_NAME
    )
    let lastName = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.LAST_NAME
    )
    let citizenShip = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.CITIZEN_SHIP
    )
    let dateOfBirth = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.DATE_OF_BIRTH
    )
    let personEmail = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.PERSON_EMAIL
    )
    let cellNum = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.CELL_NUM
    )
    let state = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.STATE
    )
    let personDocumentDTO = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDocumentDTO.TITLE
    )

    return {
      personDTO,
      crewId,
      fetchCrew,
      role,
      fastCard,
      fastCardNum,
      firstName,
      middleName,
      lastName,
      citizenShip,
      dateOfBirth,
      personEmail,
      cellNum,
      saveCrewFlag,
      state,
      personDocumentDTO
    }
  }

  const columns = [
    {
      title: `${translatedTextsObject.crewId} & ${translatedTextsObject.fastCard}`,
      render: (data) => {
        const { crewId, customProximityId } = data

        return (
          <Box>
            <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
              {crewId}
            </Typography>
            {customProximityId && (
              <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
                {customProximityId}
                <TinyChip label='FAST Card' variant='outlined' />
              </Typography>
            )}
          </Box>
        )
      }
    },
    {
      field: 'crewType',
      title: translatedTextsObject.role
    },
    {
      field: 'firstName',
      title: translatedTextsObject.firstName
    },
    {
      field: 'middleName',
      title: translatedTextsObject.middleName
    },
    {
      field: 'lastName',
      title: translatedTextsObject.lastName
    },
    {
      field: 'state',
      title: translatedTextsObject.state
    },
  ]

  function handleApplyTemplate(template) {
    if (template) {
      const {
        crewType,
        customProximityId,
        firstName,
        middleName,
        lastName,
        nationality,
        dob,
        email,
        telNo
      } = template

      setValue('role', AciAceCrewTypeMapping('ACI', crewType) || '', { shouldDirty: true })
      setValue('fastCardNum', customProximityId || '', { shouldDirty: true })
      setValue('firstName', firstName || '', { shouldDirty: true })
      setValue('middleName', middleName || '', { shouldDirty: true })
      setValue('lastName', lastName || '', { shouldDirty: true })
      setValue('citizenShip', nationality || '', { shouldDirty: true })
      setValue('dateOfBirth', moment(dob).format('YYYY-MM-DD') || '', { shouldDirty: true })
      setValue('personEmail', email || '', { shouldDirty: true })
      setValue('cellNum', telNo || '', { shouldDirty: true })

      trigger()
    } else {
      reset()
    }
  }

  function handleAddPersonDocumentDTO(data) {
    const personDocumentDTO = [...getValues('personDocumentDTO'), data].map(
      (document, index) => ({ ...document, _personDTOId, _id: index + 1 })
    )

    setValue('personDocumentDTO', personDocumentDTO, { shouldDirty: true })
  }

  function handleDeletePersonDocumentDTO(data) {
    const personDocumentDTO = [...getValues('personDocumentDTO')].filter((document) =>
      document.id ? document.id !== data.id : document._id !== data._id
    )

    setValue('personDocumentDTO', personDocumentDTO, { shouldDirty: true })
  }

	return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} lg={4} shouldHide={shouldHideMap?.fastCard}>
              <Switch
                fullWidth
                name="fastCard"
                label={translatedTextsObject.fastCard}
                disabled={disabled}
                onChange={(event) => {
                  setValue('fastCard', event.target.checked, { shouldDirty: true })
                  
                  if (event.target.checked) {
                    setValue('citizenShip', '')
                    setValue('dateOfBirth', '')
                    setValue('personDocumentDTO', [])
                  } else {
                    setValue('fastCardNum', '')
                  }
                }}
              />
            </CngGridItem>
            <CngGridItem xs={12} lg={8}>
              <Grid container spacing={1}>
                <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.saveCrewFlag}>
                  <CngCheckboxField
                    name="saveCrewFlag"
                    label={
                      <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                        {translatedTextsObject.saveCrewFlag}
                      </Typography>
                    }
                    onChange={(e) => setValue('templateFlag', e.target.checked, { shouldValidate: true })}
                    size='small'
                    style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm shouldHide={shouldHideMap?.crewId}>
                  <CngTextField
                    name="crewId"
                    label={translatedTextsObject.crewId}
                    disabled={disabled || !saveCrewFlag}
                    inputProps={{ maxLength: 15 }}
                    onChange={(e) => setValue("crewId", e.target.value.toUpperCase(), { shouldValidate: true })}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={4}>
                  <NaCrewMasterAutoCompleteField
                    name="fetchCrew"
                    label={translatedTextsObject.fetchCrew}
                    disabled={disabled}
                    onChange={(_, options) =>  handleApplyTemplate(options.data)}
                    lookupProps={{
                      filters: [
                        {
                          field: 'partyId',
                          operator: EQUAL,
                          value: FileForUserGetPartyId()
                        }]
                    }}
                    size='small'
                    fullWidth
                    disableClearable
                    textFieldProps={{
                      InputProps: {
                        customEndAdornment: () => (
                          <InputAdornment position='end' style={{ marginTop: -16 }}>
                            <IconButton onClick={(event) => {
                              event.stopPropagation()
                              setTemplateDialog(true)
                            }}>
                              <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                                <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    }}
                    forcePopupIcon={false}
                  />
                </CngGridItem>
              </Grid>
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.role}>
              <CngCodeMasterAutocompleteField
                required
                name="role"
                label={translatedTextsObject.role}
                disabled={disabled}
                codeType='ACI_HW_CREW_TYPE'
                key={role}
                onChange={(value) => setValue('role', value, { shouldDirty: true })}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.firstName}>
              <CngTextField
                required={!fastCard}
                name="firstName"
                label={translatedTextsObject.firstName}
                disabled={disabled}
                inputProps={{ maxLength: 35 }}
                onChange={(e) => setValue("firstName", e.target.value.toUpperCase(), { shouldValidate: true })}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.middleName}>
              <CngTextField
                name="middleName"
                label={translatedTextsObject.middleName}
                disabled={disabled}
                inputProps={{ maxLength:25 }}
                onChange={(e) => setValue("middleName", e.target.value.toUpperCase(), { shouldValidate: true })}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.lastName}>
              <CngTextField
                required={!fastCard}
                name="lastName"
                label={translatedTextsObject.lastName}
                disabled={disabled}
                inputProps={{ maxLength: 60 }}
                onChange={(e) => setValue("lastName", e.target.value.toUpperCase(), { shouldValidate: true })}
                size='small'
              />
            </CngGridItem>
            {fastCard ? (
              <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.fastCardNum}>
                <CngTextField
                  required
                  name="fastCardNum"
                  label={translatedTextsObject.fastCardNum}
                  disabled={disabled}
                  inputProps={{ maxLength: 80 }}
                  onChange={(e) => setValue("fastCardNum", e.target.value.toUpperCase(), { shouldValidate: true })}
                  size='small'
                />
              </CngGridItem>
            ) : (
              <>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.citizenShip}>
                  <CngCountryAutocompleteField
                    required
                    name="citizenShip"
                    label={translatedTextsObject.citizenShip}
                    disabled={disabled}
                    key={citizenShip}
                    lookupProps={{
                      label: (record) => {
                        return `${record.code};${record.descriptionEn}`
                      }
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.dateOfBirth}>
                  <CngDateField
                    required
                    name="dateOfBirth"
                    label={translatedTextsObject.dateOfBirth}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
              </>
            )}
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.cellNum}>
              <CngTextField
                name="cellNum"
                label={translatedTextsObject.cellNum}
                disabled={disabled}
                inputProps={{ maxLength: 15 }}
                onChange={(e) => setValue("cellNum", e.target.value.toUpperCase(), { shouldValidate: true })}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.personEmail}>
              <CngTextField
                name="personEmail"
                label={translatedTextsObject.personEmail}
                disabled={disabled}
                inputProps={{ maxLength: 255 }}
                onChange={(e) => setValue("personalEmail", e.target.value.toUpperCase(), { shouldValidate: true })}
                size='small'
              />
            </CngGridItem>
          </Grid>
        </Grid>
        {!fastCard && (
          <Grid item xs={12}>
            <CngSection title={translatedTextsObject.personDocumentDTO}>
              <Grid container spacing={1}>
                {personDocumentDTO.map((document, index) => (
                  <React.Fragment key={document._id || document.id || index}>
                    <Grid item xs={12}>
                      <PersonDocumentDTOEntry
                        index={index}
                        isLast={personDocumentDTO.length === 1}
                        onAddPersonDocumentDTO={handleAddPersonDocumentDTO}
                        onDeletePersonDocumentDTO={handleDeletePersonDocumentDTO}
                        personDocumentDTO={document}
                        showNotification={showNotification}
                      />
                    </Grid>
                    {personDocumentDTO.length !== index + 1 && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </CngSection>
          </Grid>
        )}
      </Grid>
      <TemplateSelectDialog
        columns={columns}
        defaultSorts={[{ field: 'crewId', direction: 'ASC' }]}
        fetch={{ url: CrewMasterApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        onApplyTemplate={handleApplyTemplate}
        onClose={() => setTemplateDialog(false)}
        open={templateDialog}
        search={{ field: 'crewId', operator: LIKE }}
        title='Crew template'
      />
    </>
	)
}

const PersonDTOFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default PersonDTOFormProperties
