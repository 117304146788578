import React, { useState } from 'react'
import { constants, useTranslation, useServices } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import AciHighwayCargoKeys from '../../../../constants/locale/key/AciHighwayCargo'
import AciHighwayCargoApiUrls from '../../../../apiUrls/AciHighwayCargoApiUrls'
import CargoDialog from '../cargo/CargoDialog'
import { Box, Chip, Typography } from '@material-ui/core'
import { getStatusMetadata } from 'src/common/NACommon'
import QuickGlanceTable from '../../../../components/aciacehighway/QuickGlanceTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TinyChip from '../../../../components/aciacehighway/TinyChip'

const {
  filter: { EQUAL, IN, LIKE }
} = constants

function CargoTable(props) {
  const { disableView = false, getCountryStateLabel, getLookupValue, manifestId, noOfCargo, redirectUrl, redirectData } = props
  const { securedSendRequest } = useServices();
  const [cargoDialog, setCargoDialog] = useState({
    open: false,
    cargo: null
  })
  const { translate } = useTranslation(Namespace.ACI_HIGHWAY_CARGO)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let ccn = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CCN
    )
    let cargoType = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CARGO_TYPE
    )
    let firstExpectedPort = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.FIRST_EXPECTED_PORT
    )
    let arrivalDateAndTime = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ARRIVAL_DATE_AND_TIME
    )
    let arrivalDateAndTimeZone = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ARRIVAL_DATE_AND_TIME_ZONE
    )
    let acquittalNo = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ACQUITTAL_NO
    )
    let status = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.STATUS
    )
    let additionalCargo = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ADDITIONAL_CARGO
    )

    let shipperName = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.SHIPPER_NAME
    )

    let consigneeName = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CONSIGNEE_NAME
    )

    let customsBrokerName = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CUSTOMS_BROKER_NAME
    )

    let customsBrokerContact = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CUSTOMS_BROKER_CONTACT
    )

    let entryNo = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ENTRY_NO
    )

    return {
      ccn,
      cargoType,
      firstExpectedPort,
      arrivalDateAndTime,
      arrivalDateAndTimeZone,
      acquittalNo,
      status,
      additionalCargo,
      shipperName,
      consigneeName,
      customsBrokerName,
      customsBrokerContact,
      entryNo
    }
  }

  const columns = [
    {
      field: 'ccn',
      sortKey: 'ccn',
      title: `${translatedTextsObject.ccn} / ${translatedTextsObject.cargoType}`,
      render: (data) => {
        return (
          <Box>
            <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
              {data.ccn}
            </Typography>
            <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
              {[
                <Typography key='cargoType' component='span' variant='inherit'>
                  {data.cargoType}
                </Typography>,
                data.acquittalNo && (
                  <Typography key='acquittalNo' component='span' variant='inherit'>
                    {data.acquittalNo}
                  </Typography>
                )
              ].reduce((acc, curr, index) => {
                return acc !== null
                  ? curr
                    ? [...acc, <Typography key={index} component='span' variant='inherit' style={{ margin: '0 4px' }} >&middot;</Typography>, curr]
                    : acc
                  : [curr]
              }, null)}
            </Typography>
          </Box>
        )
      }
    },
    {
      field: 'shipperName',
      sortKey: 'shipperName',
      title: `${translatedTextsObject.shipperName}`,
    },
    {
      field: 'consigneeName',
      sortKey: 'consigneeName',
      title: `${translatedTextsObject.consigneeName}`,
    },
    /*{
      field: 'firstExpectedPort',
      sortKey: 'firstExpectedPort',
      title: translatedTextsObject.firstExpectedPort,
      render: (data) =>
        <Typography component='div' variant='inherit'>
          {getLookupValue('firstExpectedPort', data.firstExpectedPort)}
          <TinyChip label={data.firstExpectedPort} variant='outlined' />
        </Typography>
    },
    {
      sortKey: 'arrivalDateAndTime',
      title: `${translatedTextsObject.arrivalDateAndTime} & ${translatedTextsObject.arrivalDateAndTimeZone}`,
      render: (data) =>
        data.arrivalDateAndTime && (
          <Typography component='div' variant='inherit'>
            {data.arrivalDateAndTime &&
              moment(data.arrivalDateAndTime).format('D MMM YYYY, HH:mm')}
            {data.arrivalDateAndTimeZone && (
              <TinyChip label={data.arrivalDateAndTimeZone} variant='outlined' />
            )}
          </Typography>
        )
    },*/
    {
      field: 'entryNo',
      sortKey: 'entryNo',
      title: translatedTextsObject.entryNo
    },
    {
      field: 'customsBrokerName',
      sortKey: 'customsBrokerName',
      title: translatedTextsObject.customsBrokerName
    },
    {
      field: 'status',
      sortKey: 'status',
      title: translatedTextsObject.status,
      render: (data) => {
        const status = getStatusMetadata(data.status)

        if (data.status == '1000' && data.cargoType == 'REGULAR' && !data.entryNo) {
          status.color = "#ffc400"
          status.contrastColor = "#000000"
        }

        return (
          <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>
            <Chip
              label={status.label}
              size='small'
              style={{ color: status.contrastColor, backgroundColor: status.color, fontSize: 12 }}
            />

            {data.portMismatch &&
              <Chip key='first' label={"Port Mismatch"} size='small'
                style={{ color: '#ffffff', backgroundColor: '#DF2901', fontSize: 12 }}
              />
            }
          </Box>
        )
      }
    },
    {
      title: translatedTextsObject.customsBrokerContact,
      render: (data) => {
        let content = [
          console.log("data.email", data.email)
        ]

        // <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>
        if (data.phoneNo != '' && data.phoneNo != undefined) {
          content.push(
            <Typography variant='inherit'>
              <FontAwesomeIcon icon={['fa-solid', 'fa-phone']} />
              <TinyChip label={data.phoneNo} variant='outlined' style={{ fontSize: 12 }} />
            </Typography>
          )
        }
        if (data.faxNo != '' && data.faxNo != undefined) {
          content.push(
            <Typography variant='inherit'>
              <FontAwesomeIcon icon={['fa-solid', 'fa-fax']} />
              <TinyChip label={data.faxNo} variant='outlined' style={{ fontSize: 12 }} />
            </Typography>
          )
        }
        if (data.email != '' && data.email != undefined) {
          content.push(
            <Typography variant='inherit'>
              <FontAwesomeIcon icon={['fa-solid', 'fa-envelope']} />
              <TinyChip label={data.email} variant='outlined' style={{ fontSize: 12 }} />
            </Typography>
          )
        }

        return (
          <Box display='flex' flexDirection='row' flexWrap='wrap'>
            {content}
          </Box>
        )
      }
    }
  ]

  return (
    <>
      {/*<Table
        columns={columns}
        compact
        fetch={{ url: AciHighwayCargoApiUrls.GET }}
        fetchFilters={[
          { field: 'manifestId', operator: EQUAL, value: manifestId }
        ]}
        filters={filters}
        onRowClick={(rowData) => setCargoDialog({ open: true, cargo: rowData })}
        sortConfig={{ type: 'column' }}
      />*/}
      <QuickGlanceTable
        disableView={disableView}
        columns={columns}
        compact
        fetch={{ url: AciHighwayCargoApiUrls.GET_CARGO_QUICK_GLANCE }}
        fetchFilters={[
          { field: 'manifestId', operator: EQUAL, value: manifestId }
        ]}
        //filters={filters}
        sortConfig={{ type: 'column' }}
        {...(!disableView && {
          onRowClick: (rowData) => {

            const data = {
              baseFilterDTO: {
                filterType: "AND",
                filterProperties: [
                  {
                    fieldName: "id",
                    operatorType: "EQUAL",
                    value1: rowData.id
                  }
                ],
                sortProperties: []
              }
            }
            const config = {

            }
            securedSendRequest.execute(
              'POST',
              AciHighwayCargoApiUrls.GET,
              data,
              (response) => {
                let cargoData;
                let data = response.data;

                if (data != null && data != undefined) {
                  cargoData = data.content[0]
                }

                setCargoDialog({ open: true, cargo: cargoData })
              },
              (error) => console.error(error),
              undefined,
              config,
              null
            )
          }
        })}
        noOfShipment={noOfCargo}
        redirectUrl={redirectUrl}
        redirectData={redirectData}
        additionalButtonLabel={translatedTextsObject.additionalCargo}
      />
      <CargoDialog
        getLookupValue={getLookupValue}
        getCountryStateLabel={getCountryStateLabel}
        isView
        onClose={() => setCargoDialog({ open: false, cargo: null })}
        open={cargoDialog.open}
        cargo={cargoDialog.cargo}
      />
    </>
  )
}

export default CargoTable
