import AciHwReleaseNoticeApiUrls from 'src/apiUrls/AciHwReleaseNoticeApiUrls'
import FormProperties from './FormProperties'
import { Box, Card } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'

const {
  form: { CngEditForm }
} = components

function EditPage({ showNotification }) {
  const { id } = useParams()

  return (
    <Card>
      <Box padding={2}>
        <CngEditForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: AciHwReleaseNoticeApiUrls.GET
          }}
          update={{
            url: AciHwReleaseNoticeApiUrls.PUT
          }}
          id={id}
        />
      </Box>
    </Card>
  )
}

export default EditPage
