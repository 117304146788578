import React, { useEffect, useState } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import AciHighwayResponseApiUrls from 'src/apiUrls/AciHighwayResponseApiUrls'
import AciHighwayManifestKeys from 'src/constants/locale/key/AciHighwayManifest'
import AciHighwayKeys from 'src/constants/locale/key/AciHighwayResponse'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment-timezone'
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Grid
} from '@material-ui/core'
import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls'
import clsx from 'clsx'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

function CargoResponse(props) {
  const { showNotification, id, isViewOnly = false, tripNoValue, manifestPage, cargoIdList } = props
  const [data, setData] = useState([])
  const [lookups, setLookups] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { translate } = useTranslation([Namespace.ACE_HIGHWAY_RESPONSE])
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const classes = useStyles()
  const { fetchRecords } = useServices()

  useEffect(() => {
    console.log("cargo response cargoIdList", cargoIdList)
    console.log("CargoResponse.tripNoValue", tripNoValue);
    console.log("CargoResponse.id", id);
    Promise.all([
      fetchRecords.execute(
        AciHighwayResponseApiUrls.GET,
        {
          filters: [
            {
              field: 'headerId',
              operator: 'in',
              value: manifestPage ? cargoIdList : [id]
            },
            {
              field: 'referenceIdentification',
              operator: 'in',
              value: ['XC', 'AQ', 'PAR', 'MCA', 'MCR', 'GRE', 'GRE', 'ERM', 'CSA', 'DAA', 'RNS', 'AQ-C', 'BD-C']
            },
            {
              field: 'docCode',
              operator: 'in',
              value: ['824', '997', '58', '67', '75', '489', '125', '513', '505', '455', '430', '422', '117', '174', '232', '331', '911', '927', '612', '463', '471', '257']
            }
          ]
        },
        (res) => res.content
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'ACI_HW_SUBMISSION_TYPE'
          }
        ],
        undefined,
        'code'
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACIHWY_STATUS' }],
        undefined,
        'code'
      )
    ]).then(([data, submissionType, status]) => {
      setData(data)
      setLookups({ submissionType, status })
    })
  }, [cargoIdList])

  function makeTranslatedTextsObject() {
    let title = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.CARGO_RESPONSE
    )

    let responseId = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.RESPONSE_ID
    )

    let responseDate = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.RESPONSE_DATE
    )

    let tripNo = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.TRIP_NO
    )

    let status = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.STATUS
    )

    let responseCode = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.RESPONSE_CODE
    )

    let responseDispositionCode = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.RESPONSE_DISPOSITION_CODE
    )

    let responseText = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.RESPONSE_TEXT
    )

    let entryNo = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.ENTRY_NO
    )

    let entryType = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.ENTRY_TYPE
    )

    let cbsaResponseDate = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.CBSA_RESPONSE_DATE
    )

    let ccn = translate(Namespace.ACI_HIGHWAY_RESPONSE, AciHighwayKeys.CCN)

    let submissionType = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.SUBMISSION_TYPE
    )

    let remarks = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.REMARKS
    )
    let errorCode = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.ERRORCODE
    )
    let submitId = translate(
      Namespace.ACE_HIGHWAY_RESPONSE,
      AciHighwayKeys.SUBMIT_ID
    )
    let methodOfSubmission = translate(
      Namespace.ACE_HIGHWAY_RESPONSE,
      AciHighwayKeys.METHOD
    )

    return {
      title,
      responseId,
      responseDate,
      tripNo,
      status,
      responseCode,
      responseDispositionCode,
      responseText,
      entryNo,
      entryType,
      cbsaResponseDate,
      ccn,
      submissionType,
      remarks,
      errorCode,
      submitId,
      methodOfSubmission
    }
  }

  const columns = [
    {
      field: 'batchId',
      title: translatedTextsObject.responseId
    },
    {
      field: 'receivedDate',
      title: translatedTextsObject.responseDate,
      render: (data) =>
        data.receivedDate &&
        moment(data.receivedDate).tz("Canada/Eastern").format('D MMM YYYY HH:mm')
    },
    {
      field: 'tripNo',
      title: translatedTextsObject.tripNo,
      render: (data) => data.tripNo
    },
    { field: 'ccn', title: translatedTextsObject.ccn },
    {
      field: 'errorCode',
      title: translatedTextsObject.responseDispositionCode,
      render: (data) => (
        <Grid container spacing={1}>
          {data.errorResponses.map((response, index) => (
            <Grid key={index} item xs={12}>
              <Typography variant='body2'>{response.errorCode}</Typography>
              {/* <Typography color='textSecondary' variant='caption'>
                      {response.remarks}
                      </Typography> */}
            </Grid>
          ))}
        </Grid>
      )
    },
    {
      field: 'responseText',
      title: translatedTextsObject.responseText,
      render: (data) => (
        <Grid container spacing={1}>
          {data.errorResponses.map((response, index) => (
            <Grid key={index} item xs={12}>
              <Typography variant='body2'>{response.responseText}</Typography>
              {/* <Typography color='textSecondary' variant='caption'>
                      {response.remarks}
                      </Typography> */}
              {/* <Typography variant='body2'>{response.responseText}</Typography> */}
            </Grid>
          ))}
        </Grid>
      )
    },
    { field: 'entryNo', title: translatedTextsObject.entryNo },
    { field: 'entryType', title: translatedTextsObject.entryType },
    {
      title: translatedTextsObject.submissionType,
      render: (data) =>
        lookups
          ? lookups['submissionType'][data.submissionType]
          : data.submissionType
    },
    {
      field: 'status',
      title: translatedTextsObject.status,
      render: (data) => (lookups ? lookups['status'][data.status] : data.status)
    },

    {
      title: translatedTextsObject.cbsaResponseDate,
      render: (data) =>
        data.cbsaProcessingDate &&
        moment(data.cbsaProcessingDate).tz("Canada/Eastern").format('D MMM YYYY HH:mm')
    },
    { field: 'submitId',
    title: translatedTextsObject.submitId,
    render: (data) => data.submitId }, 
    { field: 'methodOfSubmission',
    title: translatedTextsObject.methodOfSubmission,
    render: (data) => data.methodOfSubmission } 
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  return (
    <>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((d) => (
                  <TableRow key={d.id}>
                    {columns.map((column, index) => (
                      <TableCell key={index}>
                        {column.render ? column.render(d) : d[column.field]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={columns.length}>
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100, 500, 1000]}
        component='div'
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  )
}

export default CargoResponse
