import AciHighwayStatQryApiUrls from 'src/apiUrls/AciHighwayStatusQueryApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import CustomButtonSection from '../../common/CustomButtonSection'
import AciHighwayStatQryKeys from 'src/constants/locale/key/AciHighwayStatusQuery'
import Namespace from 'src/constants/locale/Namespace'


const {
  form: { CngAddForm , CngAddFormButtonSection }
} = components

function AddPage({ history, showNotification }) {

  const { translate } = useTranslation(Namespace.ACI_HIGHWAY_STAT_QRY)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let saveButton = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.SAVE_BUTTON
    )
    let submitButton = translate(
      Namespace.ACI_HIGHWAY_STAT_QRY,
      AciHighwayStatQryKeys.SUBMIT_BUTTON
    )
    return {
      saveButton,
      submitButton
    }
  }
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: AciHighwayStatQryApiUrls.POST,
            successRedirect: pathMap.ACI_HW_STAT_QRY_LIST_VIEW
          }}
          intervalToPromptUserToSubmit={60000}
          renderButtonSection={() => {
            return (
              <CngAddFormButtonSection primaryButtonLabel={translatedTextsObject.saveButton}>
                  <CustomButtonSection buttonLabel={translatedTextsObject.submitButton} url={AciHighwayStatQryApiUrls.SUBMIT} isAdd={true} showNotification={showNotification} redirect={pathMap.ACI_HW_STAT_QRY_LIST_VIEW}>
                  </CustomButtonSection>
              </CngAddFormButtonSection>
            )
          }}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage
