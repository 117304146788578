import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'
import { Grid, Divider, Typography, Card, Box, useTheme, InputAdornment, IconButton, Hidden } from '@material-ui/core'
import {
  components,
  DataFlattener,
  DateTimeFormatter,
  useServices,
  useTranslation,
  constants
} from 'cng-web-lib'
import { useFormContext, useWatch } from "react-hook-form";
import makeValidationSchema from './MakeValidationSchema'
import { NaStateCodeAutoCompleteField } from 'src/components/na'
import { NaCustomsOfficeAutocompleteField } from 'src/components/na'
import { NaConveyanceMasterAutoCompleteField } from '../../../components/na/autocomplete/codemaintenance'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngSubSection from '../../../components/cngcomponents/CngSubSection'
import CollapsibleSection from '../../../components/ui/CollapsibleSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TrailerDTOTable from './TrailerDTOTable'
import TrailerDTODialog from './TrailerDTODialog'
import PersonDTOSection from './PersonDTOSection'
import ConveyanceTemplateDialog from './ConveyanceTemplateDialog'
import { fetchUser } from 'src/views/userprofile/UserProfileService.js'
import PersonDTOFormProperties from './PersonDTOFormProperties'
import PersonDocumentDTOFormProperties from './PersonDocumentDTOFormProperties'
import _ from 'lodash'
import moment from 'moment-timezone'
import { FileForUserGetUserDetails, FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import TemplateSelectDialogWithOption from 'src/components/aciacehighway/TemplateSelectDialogWithOption'
import TemplateSelectDialogWithOptionACIHWY from '../../../components/aciacehighway/TemplateSelectDialogWithOptionACIHWY'
import ConfirmDialog from '../../common/ConfirmDialog'
import HelperText from 'src/views/common/HelperText'
import { useLocation } from 'react-router-dom'

const {
  button: { CngButton },
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngSelectField,
      CngDateField,
      CngTimeField,
      CngCodeMasterAutocompleteField,
    },
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  carrierCode: "",
  tripNum: "",
  expArrivalDate: moment().tz("Canada/Eastern").format('YYYY-MM-DD'),
  expArrivalTime: "23:59",
  firstExpectedPort: "",
  timeZone: "",
  templateFlag: false,
  templateName: "",
  status: "",
  messageFunction: "00",
  amendmentCode: "",
  saveTruckFlag: false,
  truckId: "",
  conveyanceType: "TV",
  conveyanceNum: "",
  licenseNum: "",
  countryCode: "",
  state: "",
  email: "",
  sealNo1: "",
  sealNo2: "",
  sealNo3: "",
  sealNo4: "",
  sealNo5: "",
  sealNo6: "",
  sealNo7: "",
  sealNo8: "",
  sealNo9: "",
  sealNo10: "",
  sealNo11: "",
  sealNo12: "",
  sealNo13: "",
  sealNo14: "",
  sealNo15: "",
  sealNo16: "",
  sealNo17: "",
  sealNo18: "",
  sealNo19: "",
  sealNo20: "",
  iit: "",
  lvs: "",
  postal: "",
  flyingTrucks: "",
  version: "",
  personDTO: [],
  // personDocumentDTO: [],
  trailerDTO: [],
  partyId: "",
  modeOfSubmission: "",
  oriCreatedBy: "",
  oriUpdatedBy: "",
  oriSubmittedBy: "",
  apiAutoSubmit: null,
  isEditTemplate: false,
  updateCargoPort: false,
  reminderNote: "",
  accId: "",
  userId: "",
  messageId: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

const { filter: { EQUAL } } = constants

function Fields({ disabled, showNotification, shouldHideMap, updateCargoPort, setSelectedFirstExpectedPort }) {
  const { setValue, getValues, reset, watch, trigger } = useFormContext();
  const { translate } = useTranslation(Namespace.ACIHWY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()
  const location = useLocation();

  const [manifestTemplateDialog, setManifestTemplateDialog] = useState(false)
  let templateName = getValues("templateName");
  const templateFlag = watch("templateFlag");
  const [onChangeCarrierCode, setOnChangeCarrierCode] = useState(false)
  const editPage = location.pathname.toString().indexOf("edit") != -1 ? true : false
  const [refreshCarrierCodeAndTripNo, setRefreshCarrierCodeAndTripNo] = useState(false)

  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    manifest: null
  })

  const tripNum = watch("tripNum");
  const truckSaveFlag = watch("saveTruckFlag");
  const sealNos = watch([
    'sealNo1',
    'sealNo2',
    'sealNo3',
    'sealNo4',
    'sealNo5',
    'sealNo6',
    'sealNo7',
    'sealNo8',
    'sealNo9',
    'sealNo10',
    'sealNo11',
    'sealNo12',
    'sealNo13',
    'sealNo14',
    'sealNo15',
    'sealNo16',
    'sealNo17',
    'sealNo18',
    'sealNo19',
    'sealNo20'
  ])
  const exceptionCargos = watch(['iit', 'lvs', 'postal', 'flyingTrucks'])
  const messageFunction = watch("messageFunction");

  const [conveyanceTemplateDialog, setConveyanceTemplateDialog] = useState(false)
  const trailerDTO = watch('trailerDTO')
  const personDTO = watch('personDTO')
  const countryCode = watch("countryCode");
  const firstExpectedPort = watch("firstExpectedPort");
  const timeZone = watch("timeZone");
  const state = watch("state");
  const status = watch("status")

  const isEditTemplate = getValues('isEditTemplate')

  const [user, setUser] = useState([]);
  const { securedSendRequest } = useServices();
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);

  useEffect(() => {
    setValue("updateCargoPort", updateCargoPort);
  }, [updateCargoPort]);

  useEffect(() => {
    if (setSelectedFirstExpectedPort) {
      setSelectedFirstExpectedPort(firstExpectedPort);
    }
  }, [firstExpectedPort]);

  const [scacList, setScacList] = useState([]);
  useEffect(() => {
    var scacCode = "";
    var module = "";
    var jsonArray = [];
    if (user && user.scacs) {
      Object.entries(user.scacs).forEach((item) => {
        for (const key in item[1]) {
          if (key == "scacCode") {
            scacCode = item[1][key];
          }
          if (key == "module") {
            module = item[1][key];
          }
        }

        if (module == "ACIHW") {
          var jsonObject = { text: scacCode, value: scacCode };
          jsonArray.push(jsonObject);
        }
      })
      setScacList(jsonArray);

      if (jsonArray.length > 0) {
        const isCarrierCodeFound = jsonArray && jsonArray.some(element => {
          if (element.value === getValues('carrierCode')) {
            return true;
          }
          return false;
        });

        if (editPage) {
          if (!isCarrierCodeFound) {
            setValue('carrierCode', '');
          } else {
            setOnChangeCarrierCode(true);
          }
        } else {
          if (isCarrierCodeFound) {
            setValue('carrierCode', getValues('carrierCode'));
            setValue('tripNum', getValues('carrierCode'));
            setOnChangeCarrierCode(true);
          } else {
            setValue('carrierCode', '');
            // setValue('tripNum', '');
          }
        }
        setRefreshCarrierCodeAndTripNo(false);
      }
    }
  }, [user, refreshCarrierCodeAndTripNo]);

  const [tripNumAutoGenerated, setTripNumAutoGenerated] = useState(false);
  useEffect(() => {
    if (user && user.userAutoTripNos) {
      let flag = user.userAutoTripNos[0];
      setTripNumAutoGenerated(flag && flag.autoTripNo === 'true' && flag.module.includes("ACIHWY"));
    }
  }, [user])

  useEffect(() => {
    if (status && (status !== '1005' && status != '1003')) {
      // setValue('messageFunction', '04')
    } else {
      setValue('messageFunction', '00')
    }
  }, []);

  // Push personDTO default value with crew type 'Driver' if there isn't any
  // useEffect(() => {
  //   const { initialValues: personDTOInitialValues } = PersonDTOFormProperties.formikProps
  //   const { initialValues: personDTODocumentInitialValues } = PersonDocumentDTOFormProperties.formikProps

  //   if (personDTO.length === 0) {
  //     setValue('personDTO', [
  //       {
  //         ...personDTOInitialValues,
  //         role: 'EJ',
  //         _id: 1,
  //         personDocumentDTO: [{ ...personDTODocumentInitialValues, _personDTOId: 1, _id: 1 }]
  //       }
  //     ])
  //   } else {
  //     const hasDriver = personDTO.findIndex((person) => person.role === 'EJ') !== -1

  //     if (!hasDriver) {
  //       let nextIndex = personDTO.length + 1

  //       setValue('personDTO', [
  //         ...getValues('personDTO'),
  //         {
  //           ...personDTOInitialValues,
  //           role: 'EJ',
  //           _id: nextIndex,
  //           personDocumentDTO: [{ ...personDTODocumentInitialValues, _personDTOId: nextIndex, _id: nextIndex }]
  //         }
  //       ])
  //     }
  //   }
  // }, [personDTO])

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function makeTranslatedTextsObject() {
    let manifest = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TITLE
    )
    let carrierCode = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SCAC
    )
    let tripNum = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TRIP_NUM
    )
    let expArrivalDate = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.EXP_ARRIVAL_DATE
    )
    let expArrivalDateOnly = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.EXP_ARRIVAL_DATE_ONLY
    )
    let expArrivalTime = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.EXP_ARRIVAL_TIME
    )
    let firstExpectedPort = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.FIRST_EXPECTED_PORT
    )
    let templateFlag = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TEMPLATE_FLAG
    )
    let templateName = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TEMPLATE_NAME
    )
    let timeZone = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TIMEZONE
    )
    let messageFunction = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.MESSAGE_FUNCTION
    )
    let amendmentCode = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.AMENDMENT_CODE
    )
    let trucktile = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TRUCKTITLE
    )
    let truckId = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TRUCK_ID
    )
    let conveyanceType = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.CONVEYANCE_TYPE
    )
    let conveyanceNum = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.CONVEYANCE_NUM
    )
    let licenseNum = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.LICENSE_NUM
    )
    let countryCode = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.COUNTRY_CODE
    )
    let state = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.STATE
    )
    let email = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.EMAIL
    )
    let saveTruckFlag = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SAVE_TRUCKFLAG
    )
    let fetchTruck = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.FETCH_TRUCK
    )
    let sealtitle = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_TITLE
    )
    let exceptionCargo = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.EXCEPTION_CARGO
    )
    let sealNo1 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO1
    )
    let sealNo2 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO2
    )
    let sealNo3 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO3
    )
    let sealNo4 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO4
    )
    let sealNo5 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO5
    )
    let sealNo6 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO6
    )
    let sealNo7 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO7
    )
    let sealNo8 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO8
    )
    let sealNo9 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO9
    )
    let sealNo10 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO10
    )
    let sealNo11 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO11
    )
    let sealNo12 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO12
    )
    let sealNo13 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO13
    )
    let sealNo14 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO14
    )
    let sealNo15 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO15
    )
    let sealNo16 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO16
    )
    let sealNo17 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO17
    )
    let sealNo18 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO18
    )
    let sealNo19 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO19
    )
    let sealNo20 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO20
    )
    let iit = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.IIT
    )
    let lvs = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.LVS
    )
    let postal = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.POSTAL
    )
    let flyingTrucks = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.FLYING_TRUCKS
    )
    let saveButton = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SAVE_BUTTON
    )
    let updateButton = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.UPDATE_BUTTON
    )
    let personDTO = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.TITLE
    )
    let crewId = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.CREW_ID
    )
    let role = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.ROLE
    )
    let fastCard = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.FAST_CARD
    )
    let fastCardNum = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.FAST_CARD_NUM
    )
    let firstName = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.FIRST_NAME
    )
    let middleName = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.MIDDLE_NAME
    )
    let lastName = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.LAST_NAME
    )
    let citizenShip = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.CITIZEN_SHIP
    )
    let dateOfBirth = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.DATE_OF_BIRTH
    )
    let personEmail = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.PERSON_EMAIL
    )
    let cellNum = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDTO.CELL_NUM
    )
    let personDocumentDTO = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDocumentDTO.TITLE
    )

    let _personDTOId = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDocumentDTO.PERSON_DTO
    )
    let documentType = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDocumentDTO.DOCUMENT_TYPE
    )
    let issuingCountry = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDocumentDTO.ISSUING_COUNTRY
    )
    let documentNumber = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDocumentDTO.DOCUMENT_NUMBER
    )
    let expiryDate = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDocumentDTO.EXPIRY_DATE
    )
    let trailerDTO = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.TITLE
    )
    let equipmentId = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.EQUIPMENT_ID
    )
    let cargoLoadedIn = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CARGO_LOADED_IN
    )
    let equipmentNumber = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.EQUIPMENT_NUMBER
    )
    let licensePlateNum = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.LICENSE_PLATE_NUM
    )
    let country = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.COUNTRY
    )
    let stateCode = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.STATE_CODE
    )
    let container1 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CONTAINER1
    )
    let container2 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CONTAINER2
    )
    let container3 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CONTAINER3
    )
    let container4 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CONTAINER4
    )
    let container5 = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TrailerDTO.CONTAINER5
    )
    let sealNoHideText = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO_HIDE_TEXT
    )
    let sealNoShowText = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO_SHOW_TEXT
    )
    let sealNoFooterText = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.SEAL_NO_FOOTER_TEXT
    )
    let exemptionCargoHideText = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.EXEMPTION_CARGO_HIDE_TEXT
    )
    let exemptionCargoShowText = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.EXEMPTION_CARGO_SHOW_TEXT
    )
    let exemptionCargoFooterText = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.EXEMPTION_CARGO_FOOTER_TEXT
    )
    let tripSectionHelper = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TRIP_SECTION_HELPER
    )
    let truckTrailerSectionHelper = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.TRUCK_TRAILER_SECTION_HELPER
    )
    let crewSectionHelper = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.CREW_SECTION_HELPER
    )

    return {
      manifest,
      carrierCode,
      tripNum,
      messageFunction,
      amendmentCode,
      expArrivalDate,
      expArrivalDateOnly,
      expArrivalTime,
      templateFlag,
      templateName,
      timeZone,
      firstExpectedPort,
      personDTO,
      fetchTruck,
      crewId,
      role,
      fastCard,
      fastCardNum,
      firstName,
      middleName,
      lastName,
      citizenShip,
      dateOfBirth,
      personEmail,
      cellNum,
      personDocumentDTO,
      _personDTOId,
      documentType,
      issuingCountry,
      documentNumber,
      expiryDate,
      truckId,
      exceptionCargo,
      sealtitle,
      trucktile,
      conveyanceType,
      conveyanceNum,
      licenseNum,
      countryCode,
      state,
      email,
      saveTruckFlag,
      sealNo1,
      sealNo2,
      sealNo3,
      sealNo4,
      sealNo5,
      sealNo6,
      sealNo7,
      sealNo8,
      sealNo9,
      sealNo10,
      sealNo11,
      sealNo12,
      sealNo13,
      sealNo14,
      sealNo15,
      sealNo16,
      sealNo17,
      sealNo18,
      sealNo19,
      sealNo20,
      iit,
      lvs,
      postal,
      flyingTrucks,
      trailerDTO,
      equipmentId,
      cargoLoadedIn,
      equipmentNumber,
      licensePlateNum,
      country,
      stateCode,
      container1,
      container2,
      container3,
      container4,
      container5,
      saveButton,
      updateButton,
      sealNoHideText,
      sealNoShowText,
      sealNoFooterText,
      exemptionCargoHideText,
      exemptionCargoShowText,
      exemptionCargoFooterText,
      tripSectionHelper,
      truckTrailerSectionHelper,
      crewSectionHelper,
    }
  }

  function handleApplyTemplate(template) {
    if (template) {
      const { conveyanceNo, email, licensePlates } = template

      setValue('conveyanceNum', conveyanceNo || '')
      setValue('email', email || '')

      if (licensePlates.length > 0) {
        setValue('licenseNum', licensePlates[0].licensePlateNo || '')
        setValue('countryCode', licensePlates[0].countryofRegistration || '')
        setValue('state', licensePlates[0].state || '')
      }

      showNotification('success', 'Template applied successfully.')
    } else {
      setValue('conveyanceNum', '')
      setValue('email', '')
      setValue('licenseNum', '')
      setValue('countryCode', '')
      setValue('state', '')
    }

    trigger()
  }

  function handleAddTrailerDTO(data) {
    const trailerDTO = [...getValues('trailerDTO'), data].map(
      (trailerDTO, index) => ({ ...trailerDTO, _id: index + 1 }))

    setValue('trailerDTO', trailerDTO, { shouldValidate: true })
  }

  function handleDeleteTrailerDTO(data) {
    const trailerDTO = [...getValues('trailerDTO')].filter(
      (trailerDTO) => trailerDTO.id ? trailerDTO.id !== data.id : trailerDTO._id !== data._id
    )

    setValue('trailerDTO', trailerDTO, { shouldValidate: true })
  }

  function handleEditTrailerDTO(data) {
    const trailerDTO = [...getValues('trailerDTO')]
    const index = trailerDTO.findIndex(
      (trailerDTO) => trailerDTO.id ? trailerDTO.id === data.id : trailerDTO._id === data._id
    )

    trailerDTO[index] = data

    setValue('trailerDTO', trailerDTO, { shouldValidate: true })
  }

  function getCurrentDateTime() {
    let date = new Date();

    return date.toISOString().split('.')[0].replace(/[^\d]/gi, '')
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card variant='outlined' style={{ padding: 16 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm="auto">
                <CngButton
                  onClick={() => setManifestTemplateDialog(true)}
                  size='medium'
                  startIcon={<FontAwesomeIcon icon={['fal', 'money-check-edit']} />}
                  color='secondary'
                >
                  Use template
                </CngButton>
              </Grid>
              <Hidden smDown>
                <Grid item xs={12} sm='auto'>
                  <Divider orientation='vertical' />
                </Grid>
              </Hidden>
              <Grid item xs={12} sm>
                <Grid container spacing={2} alignItems="center">
                  <CngGridItem xs="auto" shouldHide={shouldHideMap.templateFlag}>
                    <CngCheckboxField
                      disabled={disabled || isEditTemplate}
                      label={
                        <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                          {translatedTextsObject.templateFlag}
                        </Typography>
                      }
                      name="templateFlag"
                      onChange={(e) =>
                        setValue('templateFlag', e.target.checked, {
                          shouldValidate: true
                        })
                      }
                      size='small'
                      style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                    />
                  </CngGridItem>
                  <CngGridItem xs shouldHide={shouldHideMap.templateName}>
                    <CngTextField
                      required
                      name="templateName"
                      inputProps={{ maxLength: 50 }}
                      placeholder={translatedTextsObject.templateName}
                      disabled={disabled || templateFlag === false || isEditTemplate}
                      onChange={(e) => {
                        setValue("templateName", e.target.value.toUpperCase(), { shouldValidate: true })
                      }}
                      onBlur={(e) => {
                        if (e.target.value !== undefined) {
                          setValue("templateName", e.target.value.trim(), { shouldValidate: true })
                        }
                      }}
                    />
                  </CngGridItem>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <CngSection title={translatedTextsObject.manifest} helperText={translatedTextsObject.tripSectionHelper}
            subheader={
              <Typography color='textSecondary' variant='caption'>
                <Typography variant='inherit' color='error'>*</Typography>
                Mandatory fields
              </Typography>
            }
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <CngGridItem xs={12} sm={6}>
                    <Grid container spacing={1}>
                      <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.carrierCode}>
                        <CngSelectField
                          required={!isEditTemplate}
                          name="carrierCode"
                          label={translatedTextsObject.carrierCode}
                          disabled={disabled || getValues('status') === '1000'}
                          items={scacList}
                          onChange={(e) => {
                            if (tripNumAutoGenerated) {
                              setValue("tripNum", e.target.value.toUpperCase() + getCurrentDateTime(), { shouldValidate: true })
                            } else if (tripNum === '') {
                              setOnChangeCarrierCode(true);
                              setValue("tripNum", e.target.value.toUpperCase(), { shouldValidate: true })
                            } else {
                              if (onChangeCarrierCode) {
                                var removedPrefix = tripNum.substring(4);
                                setValue("tripNum", e.target.value.toUpperCase() + removedPrefix, { shouldValidate: true })
                              } else {
                                setOnChangeCarrierCode(true);
                                setValue("tripNum", e.target.value.toUpperCase() + tripNum, { shouldValidate: true })
                              }
                            }
                          }}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={9} shouldHide={shouldHideMap.tripNum}>
                        <CngTextField
                          required={!isEditTemplate}
                          name="tripNum"
                          label={translatedTextsObject.tripNum}
                          disabled={tripNumAutoGenerated || getValues('status') === '1000' || isEditTemplate}
                          inputProps={{ maxLength: 25 }}
                          onChange={(e) => {
                            setValue("tripNum", e.target.value.toUpperCase(), { shouldValidate: true })
                          }}
                          onBlur={(e) => {
                            if (e.target.value !== undefined) {
                              setValue("tripNum", e.target.value.trim(), { shouldValidate: true })
                            }
                          }}
                          size='small'
                        />
                      </CngGridItem>
                    </Grid>
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.firstExpectedPort}>
                    <NaCustomsOfficeAutocompleteField
                      required
                      name="firstExpectedPort"
                      label={translatedTextsObject.firstExpectedPort}
                      disabled={disabled}
                      inputProps={{ maxLength: 30 }}
                      size='small'
                      lookupProps={{
                        filters: [{
                          field: 'indicator',
                          operator: 'equal',
                          value: 'ACI'
                        }]
                      }}
                      key={firstExpectedPort}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.expArrivalDateOnly}>
                    <CngDateField
                      required
                      name="expArrivalDate"
                      label={translatedTextsObject.expArrivalDateOnly}
                      format="YYYY-MM-DD"
                      disabled={disabled /*|| getValues('status') === '1000' */}
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.expArrivalTime}>
                    <CngTimeField
                      required
                      name="expArrivalTime"
                      label={translatedTextsObject.expArrivalTime}
                      format="HH:mm"
                      disabled={disabled}
                      size='small'
                      ampm={false}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.timeZone}>
                    <CngCodeMasterAutocompleteField
                      name='timeZone'
                      label={translatedTextsObject.timeZone}
                      disabled={disabled}
                      codeType='SCHEDULER_TIME_ZONE'
                      size='small'
                      key={timeZone}
                    />
                  </CngGridItem>
                </Grid>
              </Grid>
            </Grid>
            <TemplateSelectDialogWithOptionACIHWY
              manifestTemplateDialog={manifestTemplateDialog}
              setManifestTemplateDialog={setManifestTemplateDialog}
              setConfirmDialog={setConfirmDialog}
              showNotification={showNotification}
              translatedTextsObject={translatedTextsObject}
              setValue={setValue}
              reset={reset}
              trigger={trigger}
              setOnChangeCarrierCode={setOnChangeCarrierCode}
              setRefreshCarrierCodeAndTripNo={setRefreshCarrierCodeAndTripNo}
            />
          </CngSection>

        </Grid>
        <Grid item xs={12}>
          <CngSection
            helperText={translatedTextsObject.truckTrailerSectionHelper}
            title='Truck (Conveyance)'
            subheader={
              <Typography color='textSecondary' variant='caption'>
                <Typography variant='inherit' color='error'>
                  *
                </Typography>
                Mandatory fields
              </Typography>
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.saveTruckFlag}>
                    <CngCheckboxField
                      label={
                        <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                          {translatedTextsObject.saveTruckFlag}
                        </Typography>
                      }
                      name='saveTruckFlag'
                      disabled={disabled}
                      onChange={(e) =>
                        setValue('saveTruckFlag', e.target.checked, {
                          shouldValidate: true
                        })
                      }
                      size='small'
                      style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.truckId}>
                    <CngTextField
                      name='truckId'
                      label={translatedTextsObject.truckId}
                      disabled={disabled || !truckSaveFlag}
                      inputProps={{ maxLength: 15 }}
                      onChange={(e) => {
                        setValue('truckId', e.target.value.toUpperCase(), {
                          shouldValidate: true
                        })
                      }}
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} md={4}>
                    <NaConveyanceMasterAutoCompleteField
                      name='fetchTruck'
                      label={translatedTextsObject.fetchTruck}
                      disabled={disabled}
                      onChange={(_, options) =>
                        handleApplyTemplate(options.data)
                      }
                      size='small'
                      fullWidth
                      disableClearable
                      textFieldProps={{
                        InputProps: {
                          customEndAdornment: () => (
                            <InputAdornment position='end' style={{ marginTop: -16 }}>
                              <IconButton onClick={(event) => {
                                event.stopPropagation()
                                setConveyanceTemplateDialog(true)
                              }}>
                                <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                                  <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                                </Box>
                              </IconButton>
                            </InputAdornment>
                          )
                        }
                      }}
                      forcePopupIcon={false}
                      lookupProps={{
                        filters: [{
                          field: 'partyId',
                          operator: EQUAL,
                          value: FileForUserGetPartyId()
                        }]
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.conveyanceType}>
                    <CngCodeMasterAutocompleteField
                      required
                      name="conveyanceType"
                      label={translatedTextsObject.conveyanceType}
                      disabled={disabled}
                      codeType='ACIHW_MANI_CONVEYANCE_TYPE'
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.conveyanceNum}>
                    <CngTextField
                      required
                      name="conveyanceNum"
                      label={translatedTextsObject.conveyanceNum}
                      disabled={disabled}
                      inputProps={{ maxLength: 15 }}
                      onChange={(e) => {
                        setValue("conveyanceNum", e.target.value.toUpperCase(), { shouldValidate: true })
                      }}
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.email}>
                    <CngTextField
                      name="email"
                      label={translatedTextsObject.email}
                      disabled={disabled}
                      inputProps={{ maxLength: 256 }}
                      onChange={(e) => {
                        setValue("email", e.target.value.toUpperCase(), { shouldValidate: true })
                      }}
                      size='small'
                    />
                  </CngGridItem>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CngSubSection title='License plate'>
                  <Grid container spacing={1}>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.licenseNum}>
                      <CngTextField
                        required
                        name="licenseNum"
                        label={translatedTextsObject.licenseNum}
                        disabled={disabled}
                        inputProps={{ maxLength: 80 }}
                        onChange={(e) => {
                          setValue("licenseNum", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.countryCode}>
                      <CngCodeMasterAutocompleteField
                        required
                        name="countryCode"
                        label={translatedTextsObject.countryCode}
                        disabled={disabled}
                        codeType='ACIHWY_MANIFEST_COUNTRY'
                        key={countryCode}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.state}>
                      <NaStateCodeAutoCompleteField
                        required
                        name="state"
                        label={translatedTextsObject.state}
                        disabled={disabled}
                        countryCode={countryCode}
                        key={countryCode + state}
                        size='small'
                      />
                    </CngGridItem>
                  </Grid>
                </CngSubSection>
              </Grid>
              <Grid item xs={12}>
                <CollapsibleSection
                  defaultExpanded={sealNos.filter((sealNo) => sealNo).length > 0}
                  title={translatedTextsObject.sealtitle}
                >
                  <Grid container spacing={1}>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.sealNo1}>
                      <CngTextField
                        name="sealNo1"
                        label={translatedTextsObject.sealNo1}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("sealNo1", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.sealNo2}>
                      <CngTextField
                        name="sealNo2"
                        label={translatedTextsObject.sealNo2}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("sealNo2", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.sealNo3}>
                      <CngTextField
                        name="sealNo3"
                        label={translatedTextsObject.sealNo3}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("sealNo3", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.sealNo4}>
                      <CngTextField
                        name="sealNo4"
                        label={translatedTextsObject.sealNo4}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("sealNo4", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.sealNo5}>
                      <CngTextField
                        name="sealNo5"
                        label={translatedTextsObject.sealNo5}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("sealNo5", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.sealNo6}>
                      <CngTextField
                        name="sealNo6"
                        label={translatedTextsObject.sealNo6}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("sealNo6", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.sealNo7}>
                      <CngTextField
                        name="sealNo7"
                        label={translatedTextsObject.sealNo7}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("sealNo7", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.sealNo8}>
                      <CngTextField
                        name="sealNo8"
                        label={translatedTextsObject.sealNo8}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("sealNo8", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.sealNo9}>
                      <CngTextField
                        name="sealNo9"
                        label={translatedTextsObject.sealNo9}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("sealNo9", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.sealNo10}>
                      <CngTextField
                        name="sealNo10"
                        label={translatedTextsObject.sealNo10}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("sealNo10", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.sealNo11}>
                      <CngTextField
                        name="sealNo11"
                        label={translatedTextsObject.sealNo11}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("sealNo11", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.sealNo12}>
                      <CngTextField
                        name="sealNo12"
                        label={translatedTextsObject.sealNo12}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("sealNo12", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.sealNo13}>
                      <CngTextField
                        name="sealNo13"
                        label={translatedTextsObject.sealNo13}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("sealNo13", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.sealNo14}>
                      <CngTextField
                        name="sealNo14"
                        label={translatedTextsObject.sealNo14}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("sealNo14", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.sealNo15}>
                      <CngTextField
                        name="sealNo15"
                        label={translatedTextsObject.sealNo15}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("sealNo15", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.sealNo16}>
                      <CngTextField
                        name="sealNo16"
                        label={translatedTextsObject.sealNo16}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("sealNo16", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.sealNo17}>
                      <CngTextField
                        name="sealNo17"
                        label={translatedTextsObject.sealNo17}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("sealNo17", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.sealNo18}>
                      <CngTextField
                        name="sealNo18"
                        label={translatedTextsObject.sealNo18}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("sealNo18", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.sealNo19}>
                      <CngTextField
                        name="sealNo19"
                        label={translatedTextsObject.sealNo19}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("sealNo19", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.sealNo20}>
                      <CngTextField
                        name="sealNo20"
                        label={translatedTextsObject.sealNo20}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("sealNo20", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                  </Grid>
                </CollapsibleSection>
              </Grid>
              <Grid item xs={12}>
                <CollapsibleSection
                  defaultExpanded={exceptionCargos.filter((exceptionCargo) => exceptionCargo).length > 0}
                  title={translatedTextsObject.exceptionCargo}
                >
                  <Grid container spacing={1}>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.iit}>
                      <CngTextField
                        name="iit"
                        label={translatedTextsObject.iit}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("iit", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.lvs}>
                      <CngTextField
                        name="lvs"
                        label={translatedTextsObject.lvs}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("lvs", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.postal}>
                      <CngTextField
                        name="postal"
                        label={translatedTextsObject.postal}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("postal", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.flyingTrucks}>
                      <CngTextField
                        name="flyingTrucks"
                        label={translatedTextsObject.flyingTrucks}
                        disabled={disabled}
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setValue("flyingTrucks", e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        size='small'
                      />
                    </CngGridItem>
                  </Grid>
                </CollapsibleSection>
              </Grid>
            </Grid>
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <CngSection title={translatedTextsObject.trailerDTO} helperText={translatedTextsObject.truckTrailerSectionHelper} alignItems='flex-end'>
            <TrailerDTOTable
              data={trailerDTO}
              onAddTrailerDTO={handleAddTrailerDTO}
              onDeleteTrailerDTO={handleDeleteTrailerDTO}
              onEditTrailerDTO={handleEditTrailerDTO}
              showNotification={showNotification}
            />
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <PersonDTOSection
            personDTO={personDTO}
            showNotification={showNotification}
            title='Driver / Crew / Passenger'
            crewSectionHelper={translatedTextsObject.crewSectionHelper}
          />
        </Grid>
        <Grid item xs={12}>
          <CngSection title={translatedTextsObject.messageFunction}>
            <Grid container spacing={1}>
              <CngGridItem xs={12} shouldHide={shouldHideMap.messageFunction}>
                <CngCodeMasterAutocompleteField
                  required
                  name='messageFunction'
                  label={translatedTextsObject.messageFunction}
                  disabled={disabled}
                  codeType='ACIHW_MESSAGE_FUNCTION'
                  size='small'
                  key={messageFunction}
                />
              </CngGridItem>
              {(messageFunction === 'CO') && (
                <CngGridItem xs={12}>
                  <CngCodeMasterAutocompleteField
                    required
                    name='amendmentCode'
                    label={translatedTextsObject.amendmentCode}
                    disabled={disabled}
                    codeType='ACIHW_MANIFEST_AMENDMENT_CODE'
                    size='small'
                  />
                </CngGridItem>
              )}
            </Grid>
          </CngSection>
        </Grid>
      </Grid>
      <ConveyanceTemplateDialog
        open={conveyanceTemplateDialog}
        onApplyTemplate={handleApplyTemplate}
        onClose={() => setConveyanceTemplateDialog(false)}
      />
    </>
  )
}

function toClientDataFormat(serverData) {
  // let localData = DataFlattener.parse(serverData);
  let localData = serverData;

  localData.expArrivalTime = moment(localData.expArrivalDate).format('HH:mm');
  localData.expArrivalDate = moment(localData.expArrivalDate).format('YYYY-MM-DD');

  localData.personDTO.forEach((datum) => {
    datum.dateOfBirth = DateTimeFormatter.toClientDate(
      datum.dateOfBirth
    );

    datum.personDocumentDTO.forEach((datum) => {
      datum.expiryDate = DateTimeFormatter.toClientDate(
        datum.expiryDate
      );
    });
  });
  return localData;
}

function toServerDataFormat(localData) {

  localData.expArrivalDate = localData.expArrivalDate + ' ' + localData.expArrivalTime

  localData.personDTO.forEach((datum) => {
    datum.dateOfBirth = DateTimeFormatter.toServerDate(
      datum.dateOfBirth
    );

    datum.personDocumentDTO.forEach((datum) => {
      datum.expiryDate = DateTimeFormatter.toServerDate(
        datum.expiryDate
      );
    });
  });
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
