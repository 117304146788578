import makeValidationSchema from './ContainersMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AciHwReleaseNoticeKeys from 'src/constants/locale/key/AciHwReleaseNotice'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  containerNumber: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.ACI_HW_RELEASE_NOTICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let containers = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.Containers.TITLE
    )
    let containerNumber = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.Containers.CONTAINER_NUMBER
    )

    return {
      containers,
      containerNumber
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.containers} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containerNumber}>
            <CngTextField
              name="containerNumber"
              label={translatedTextsObject.containerNumber}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

const ContainersFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default ContainersFormProperties
