import React, { useRef, useEffect } from 'react'
import { components } from 'cng-web-lib'
import CngSection from '../../../components/cngcomponents/CngSection'
import PersonDTOEntry from './PersonDTOEntry'
import { Grid, Tooltip } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'
import PersonDTOFormProperties from './PersonDTOFormProperties'
import PersonDocumentDTOFormProperties from './PersonDocumentDTOFormProperties'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  button: { CngIconButton }
} = components

const { initialValues } = PersonDTOFormProperties.formikProps

function PersonDTOSection(props) {
  const { personDTO, showNotification, title, crewSectionHelper } = props

  const { getValues, setValue } = useFormContext()
  const lastIndex = useRef(personDTO.length)

  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      personDTO.forEach(data => {
        data.fileForUserId = fileForUserDetails.fileForUserId;
        data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
        data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
        data.personDocumentDTO.forEach(documentData => {
          documentData.fileForUserId = fileForUserDetails.fileForUserId;
          documentData.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
          documentData.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
        });
      });
    }
  }, [personDTO]);


  function handleAddPersonDTO(data) {
    const result = { ...data }
    const newIndex = ++lastIndex.current

    if (!result.personDocumentDTO || result.personDocumentDTO.length === 0) {
      const { initialValues } = PersonDocumentDTOFormProperties.formikProps
      result.personDocumentDTO = [{ ...initialValues, _id: 1, _personDTOId: newIndex }]
    }

    const personDTO = [...getValues('personDTO'), { ...data, _id: newIndex }]

    setValue('personDTO', personDTO)
  }

  function handleClonePersonDTO(data) {
    const result = { ...data }

    result.personDocumentDTO.forEach((document, index) => {
      document.id = undefined
      document._id = index + 1
      document._personDTOId = lastIndex.current + 1
    })

    handleAddPersonDTO(result)
  }

  function handleDeletePersonDTO(data) {
    const personDTO = [...getValues('personDTO')].filter(
      (person) => person.id ? person.id !== data.id : person._id !== data._id
    )

    setValue('personDTO', personDTO)
  }

  function handleEditPersonDTO(data) {
    const personDTO = [...getValues('personDTO')]
    const index = personDTO.findIndex((person) =>
      person.id ? person.id === data.id : person._id === data._id
    )

    personDTO[index] = data

    setValue('personDTO', personDTO)
  }

  return (
    <CngSection title={title} helperText={crewSectionHelper} alignItems='flex-end'>
      <Grid container spacing={2}>
        {personDTO.map((person) => (
          <Grid key={Math.random()} item xs={12}>
            <PersonDTOEntry
              personDTO={person}
              onAddPersonDTO={handleAddPersonDTO}
              onClonePersonDTO={handleClonePersonDTO}
              onDeletePersonDTO={handleDeletePersonDTO}
              onEditPersonDTO={handleEditPersonDTO}
              showNotification={showNotification}
            />
          </Grid>
        ))}
        {personDTO.length === 0 && (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Add new'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'plus']}
                      onClick={() => handleAddPersonDTO(initialValues)}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </CngSection>
  )
}

export default PersonDTOSection
