import AciHwReleaseNoticeApiUrls from 'src/apiUrls/AciHwReleaseNoticeApiUrls'
import FormProperties from './FormProperties'
import { Box, Card } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'

const {
  form: { CngAddForm }
} = components

function AddPage({ history, showNotification }) {
  return (
    <Card>
      <Box padding={2}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: AciHwReleaseNoticeApiUrls.POST,
            successRedirect: pathMap.ACI_HW_RELEASE_NOTICE_LIST_VIEW
          }}
        />
      </Box>
    </Card>
  )
}

export default AddPage
