import { Yup } from 'cng-web-lib'
import moment from 'moment'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'


const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  let alphaNumericRegx = translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.ALPHA_NUMERIC_REGX
  )
  let enterValidInput=translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.ENTER_VALID_INPUT
  )
  let expiryDateValidation = translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.EXPIRY_DATE
  )

  return Yup.object({
    _personDTOId: Yup.number().required(requiredMessage).positive(requiredMessage).integer(enterValidInput),
    documentType: Yup.string().required(requiredMessage).nullable(),
    issuingCountry: Yup.string().when('documentType',{
    is: (value) => (value!==null && value!==""),
    then: Yup.string().required(requiredMessage)
    }).nullable(),
    documentNumber: Yup.string().when('documentType',{
      is: (value) => (value!==null && value!==""),
      then: Yup.string().required(requiredMessage)
      }).matches("^[a-zA-Z0-9]*$", alphaNumericRegx).nullable(),
    expiryDate: Yup.date().validFormat().typeError(dateTypeErrorMessage).required(requiredMessage).nullable(),
    expiryDate: Yup.string().required(requiredMessage).test('',expiryDateValidation,function(value){
      if(value!=null)
      {
       let givenDate = moment(value).format('YYYY-MM-DD');
       let todaysDate = moment(new Date()).format('YYYY-MM-DD');
        let checkDate = moment(givenDate).isSameOrBefore(todaysDate);
        if(checkDate){
             return false;
         }
         else
         return true;
      }
     },
     ).nullable(),
   
  })
}

export default makeValidationSchema
