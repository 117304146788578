import makeValidationSchema from './PersonDocumentDTOMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'
import Grid from '@material-ui/core/Grid'
import { useFormContext, useWatch } from "react-hook-form";

const {
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  _personDTOId: 0,
  documentType: "",
  issuingCountry: "",
  documentNumber: "",
  expiryDate: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  index
}) {
  const { translate } = useTranslation(Namespace.ACIHWY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue } = useFormContext();
  const { personDocumentDTO } = useWatch()
  
  function makeTranslatedTextsObject() {
    let personDocumentDTO = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDocumentDTO.TITLE
    )

    let _personDTOId = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDocumentDTO.PERSON_DTO
    )
   
    let documentType = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDocumentDTO.DOCUMENT_TYPE
    )
    let issuingCountry = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDocumentDTO.ISSUING_COUNTRY
    )
    let documentNumber = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDocumentDTO.DOCUMENT_NUMBER
    )
    let expiryDate = translate(
      Namespace.ACIHWY_MANIFEST,
      ManifestKeys.PersonDocumentDTO.EXPIRY_DATE
    )

    return {
      personDocumentDTO,
      _personDTOId,
      documentType,
      issuingCountry,
      documentNumber,
      expiryDate
    }
  }

	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.documentType}>
        <CngCodeMasterAutocompleteField
          name={typeof index === 'number' ? `personDocumentDTO.${index}.documentType` : 'documentType'}
          label={translatedTextsObject.documentType}
          disabled={disabled}
          codeType='ACIHWY_DOCUMENT_TYPE'
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.issuingCountry}>
        <CngCountryAutocompleteField
          required={personDocumentDTO ? personDocumentDTO[index].documentType : false}
          name={typeof index === 'number' ? `personDocumentDTO.${index}.issuingCountry` : 'issuingCountry'}
          label={translatedTextsObject.issuingCountry}
          disabled={disabled}
          lookupProps={{
            label: (record) => {
              return `${record.code};${record.descriptionEn}`
            }
          }}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.documentNumber}>
        <CngTextField
          required={personDocumentDTO ? personDocumentDTO[index].documentType : false}
          name={typeof index === 'number' ? `personDocumentDTO.${index}.documentNumber` : 'documentNumber'}
          label={translatedTextsObject.documentNumber}
          disabled={disabled}
          inputProps={{ maxLength: 80 }}
          onChange={(e) => {
            const name = typeof index === 'number' ? `personDocumentDTO.${index}.documentNumber` : 'documentNumber'
            setValue(name, e.target.value.toUpperCase(), { shouldValidate: true })
          }}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.expiryDate}>
        <CngDateField
          name={typeof index === 'number' ? `personDocumentDTO.${index}.expiryDate` : 'expiryDate'}
          label={translatedTextsObject.expiryDate}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
	)
}

const PersonDocumentDTOFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default PersonDocumentDTOFormProperties
