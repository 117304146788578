import React from 'react'
import { components, constants, useTranslation, useServices } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AciHighwayCargoKeys from 'src/constants/locale/key/AciHighwayCargo'
import CargoViewContent from '../../cargo/CargoViewContent'
import FormProperties from '../../cargo/FormProperties'
import { Box } from '@material-ui/core'
import moment from 'moment'

const {
  button: { CngButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function CargoDialog(props) {
  const {
    cargo,
    expectedArrivalDateTimeValue,
    expectedArrivalTimeZoneValue,
    firstExpectedPortValue,
    isView,
    getLookupValue,
    getCountryStateLabel,
    manifestId,
    loading,
    onClose,
    onCreateCargo,
    onEditCargo,
    open,
    showNotification,
    manifestCarrierCode
  } = props
  const { initialValues, makeValidationSchema } = FormProperties.formikProps

  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_SHIPMENT)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let updateButton = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.UPDATE_BUTTON
    )
    let saveButton = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.SAVE_BUTTON
    )
    let firstExpectedPort = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.FIRST_EXPECTED_PORT
    )
    let arrivalDateAndTime = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ARRIVAL_DATE_AND_TIME
    )


    return {
      updateButton,
      saveButton,
      firstExpectedPort,
      arrivalDateAndTime
    }
  }

  let viewContent = (
    <CargoViewContent
      data={cargo}
      getLookupValue={getLookupValue}
      getCountryStateLabel={getCountryStateLabel}
      manifestId={manifestId}
    />
  )

  return (
    <>
      <CngDialog
        dialogContent={
          cargo && isView ? (viewContent) : (
            <CngForm
              fieldLevel='form'
              hookFormProps={{
                initialValues: {
                  ...(cargo || initialValues),
                  arrivalDateAndTime: cargo === null ? expectedArrivalDateTimeValue : (null !== cargo.arrivalDateAndTime && '' !== cargo.arrivalDateAndTime ? moment(cargo.arrivalDateAndTime) : '') || '',
                  arrivalTime: cargo === null ? moment(expectedArrivalDateTimeValue).format('HH:mm') : (null !== cargo.arrivalDateAndTime && '' !== cargo.arrivalDateAndTime ? moment(cargo.arrivalDateAndTime).format('HH:mm') : '') || '',
                  arrivalDate: cargo === null ? moment(expectedArrivalDateTimeValue).format('YYYY-MM-DD') : (null !== cargo.arrivalDateAndTime && '' !== cargo.arrivalDateAndTime ? moment(cargo.arrivalDateAndTime).format('YYYY-MM-DD') : '') || '',
                  arrivalDateAndTimeZone: cargo === null ? expectedArrivalTimeZoneValue : cargo.arrivalDateAndTimeZone || '',
                  firstExpectedPort: cargo === null ? firstExpectedPortValue : cargo.firstExpectedPort || '',
                  headerId: manifestId,
                  manifestId: manifestId
                },
                makeValidationSchema: makeValidationSchema,
                onSubmit: (data) => {
                  if (cargo) {
                    onEditCargo(data)
                  } else {
                    onCreateCargo(data)
                  }
                }
              }}
              formState={FormState.COMPLETED}
              renderBodySection={(labelMap, shouldHideMap) => (
                <FormProperties.Fields
                  showNotification={showNotification}
                  shouldHideMap={shouldHideMap}
                  manifestCarrierCode={manifestCarrierCode}
                />
              )}
              innerForm={true}
              renderButtonSection={() => (
                <Box display='flex' justifyContent='flex-end'>
                  <CngButton
                    color='primary'
                    size='medium'
                    type='submit'
                    disabled={loading}
                    shouldShowProgress={loading}
                  >
                    {cargo ? translatedTextsObject.updateButton : translatedTextsObject.saveButton}
                  </CngButton>
                </Box>
              )}
            />
          )
        }
        dialogAction={isView ? <CngButton onClick={onClose}>OK</CngButton> : null}
        dialogTitle='Cargo'
        open={open}
        onClose={onClose}
        maxWidth='lg'
        fullWidth
      />
    </>
  )
}

export default CargoDialog
