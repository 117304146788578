import React, { useEffect, useState } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import CngSection from '../../../../components/cngcomponents/CngSection'
import ManifestResponse from '../../response/ManifestResponse'
import CargoResponse from '../../response/CargoResponse'
import AciHighwayCargoApiUrls from '../../../../apiUrls/AciHighwayCargoApiUrls'

import Grid from '@material-ui/core/Grid';
import Namespace from 'src/constants/locale/Namespace'
import AciHighwayKeys from 'src/constants/locale/key/AciHighwayResponse'
const { CngTabs } = components
const {
  filter: { EQUAL }
} = constants

function ResponseLog(props) {
  const { fetchRecords, securedSendRequest } = useServices()
  const { manifestId, tripNum } = props
  const [cargoIdList, setCargoIdList] = useState([])
  const { translate } = useTranslation(Namespace.ACI_HIGHWAY_RESPONSE)
  const translatedTextsObject = makeTranslatedTextsObject()
  function makeTranslatedTextsObject() {
    let manifestResponseTitle = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.MANIFEST_RESPONSE
    )
    let cargoResponseTitle = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayKeys.CARGO_RESPONSE
    )
    return {
      manifestResponseTitle,
      cargoResponseTitle,
    }
  }
  useEffect(() => {
    console.log("cargo response manifestId", manifestId)
    function onSuccess(response) {
      setCargoIdList(response.data)
    }

    function onError(error) {
      console.log(error);
    }

    function onComplete() {

    }
    const config = {}

    securedSendRequest.execute('POST', AciHighwayCargoApiUrls.GET_CARGO_BY_MANIFEST, { manifestId }, onSuccess, onError, onComplete, config, null);
  }, []);
  
  return (
	<Grid container spacing={2}>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.manifestResponseTitle}>
          <ManifestResponse
            id={manifestId}
            isViewOnly={true}
          />
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.cargoResponseTitle}>
          <CargoResponse
            id={manifestId}
            isViewOnly={true}
		 	      tripNoValue={tripNum}
			      manifestPage={true}
			      cargoIdList={cargoIdList && cargoIdList.length > 0 ? cargoIdList :[0]}
          />
        </CngSection>
      </Grid>
    </Grid>
  )
}

export default ResponseLog
