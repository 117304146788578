import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {constants} from 'cng-web-lib'
import moment from 'moment'
import ManifestKeys from 'src/constants/locale/key/AciHighwayManifest'


const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  ) 
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR 
  )
  let carrierCodeLength = translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.CARRIER_CODE_LENGTH
  )
  let carrierCodeRegX= translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.CARRIER_CODE_REGX
  )
  let tripNumLength = translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.TRIP_NUM_LENGTH
  )
  let tripNumPrefix = translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.TRIP_NUM_PREFIX
  )
  let expArrivalDateValidation = translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.EXP_ARRIVAL_DATE
  )
  let alphaNumericRegx = translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.ALPHA_NUMERIC_REGX
  )
  let expArrivalDateRegx = translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.EXP_ARRIVAL_DATE_REGX
  )
  let alphaNumericSpaceRegx = translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.ALPHA_NUMERIC_SPACE_REGX
  )
  let numericRegx= translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.NUMERIC_REGX
  )
  let emailMaxLength=translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.EMAIL_MAX_LENGTH
  )
  let emailValid=translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.EMAIL_VALID
  )
  let msgfunForAC=translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.MSGFUN_FOR_AC
  )
  let msgfunForDR=translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.MSGFUN_FOR_DR
  )
  let msgfunForCL=translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.MSGFUN_FOR_CL
  )
  let msgfunForMR=translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.MSGFUN_FOR_MR
  )
  let msgfunForRJ=translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.MSGFUN_FOR_RJ
  )
  let msgfunForCR=translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.MSGFUN_FOR_CR
  )
  let msgfunForNewRec=translate(
    Namespace.ACIHWY_MANIFEST,
    ManifestKeys.Validations.MSGFUN_FOR_NEW_REC
  )

  return Yup.object({
    carrierCode: Yup.string().when('templateFlag', {
      is: (value)=>value === false,
      then: Yup.string().matches("^[a-zA-Z0-9\-]{4}$",carrierCodeRegX).required(requiredMessage).min(4, carrierCodeLength),
    }).nullable(),
    tripNum: Yup.string().when('templateFlag', {
      is: (value)=>value === false,
      then: Yup.string().matches("^[a-zA-Z0-9\-]+$", alphaNumericRegx).required(requiredMessage).min(5, tripNumLength)
      .test("tripNum",tripNumPrefix,function(){
        let carrierCode = this.parent["carrierCode"];
        let tripNum = this.parent["tripNum"];
      if(tripNum && carrierCode) {
        if(tripNum.startsWith(carrierCode))
         return true;
        else
         return false;
      }
      }),
    }).nullable(),  
    expArrivalDate: Yup.string().required(requiredMessage)
      .test(
        'expArrivalDate',
        expArrivalDateRegx,
        value =>  {
          return moment(value, 'YYYY-MM-DD').isValid()
        },
      ).nullable(),
    expArrivalTime: Yup.string().required(requiredMessage).test(
        'expArrivalTime',
        expArrivalDateRegx,
        value =>  {
          return moment(value, 'HH:mm').isValid()
        },
      ).nullable(),
    firstExpectedPort: Yup.string().required(requiredMessage).nullable(),
    saveTruckFlag: Yup.boolean().nullable(),
    truckId: Yup.string().when('saveTruckFlag',{
      is: true,
      then: Yup.string().required(requiredMessage)
    }).nullable(),
    templateFlag: Yup.boolean().required(requiredMessage),
    templateName: Yup.string().when('templateFlag', {
      is: true,
      then: Yup.string().trim().required(requiredMessage).matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    }).nullable(),
    timeZone: Yup.string().nullable(),
    status: Yup.string().nullable(),
    messageFunction: Yup.string().when('status',{
      is: (value)=>value === "1000" || value ==="1006",
      then: Yup.string().test('messageFunction',msgfunForAC,function(value){
        if(value == "00") {
          return false;
        } else {
          return true;
        }
      }),
      otherwise: Yup.string().when('status',{
        is: (value) => value === "1005",
        then: Yup.string().test('messageFunction',msgfunForDR,function(value){
          if(value != "00"){
            return false;
          } else {
            return true;
          } 
        }),
        otherwise: Yup.string().when('status',{
          is: (value) => value === "1003",
          then: Yup.string().test('messageFunction',msgfunForCL,function(value){
            if(value != "00"){
              return false;
            } else {
              return true;
            } 
          }),
          otherwise: Yup.string().when('status',{
            is: (value) => value === "1007",
            then: Yup.string().test('messageFunction',msgfunForMR,function(value){
              if(value === "04" || value==="03" ){
                return false;
              } else {
                return true;
              } 
            })
          }),
          otherwise: Yup.string().when('status',{
            is: (value) => value === "1001",
            then: Yup.string().test('messageFunction',msgfunForRJ,function(value){
              if(value !== "04" ){
                return false;
              } else {
                return true;
              } 
            })
          }),
          otherwise: Yup.string().when('status',{
            is: (value) => value === "1004",
            then: Yup.string().test('messageFunction',msgfunForCR,function(value){
              if(value === "04" || value==="03" ){
                return false;
              } else {
                return true;
              } 
            })
          }),
          otherwise: Yup.string().when('status',{
            is: (value) => value === "" || value===null,
            then: Yup.string().test('messageFunction',msgfunForNewRec,function(value){
              if(value != "00"){
                return false;
              } else {
                return true;
              } 
            })
          }),

        })
      })
    }).nullable().required(requiredMessage),
    conveyanceType: Yup.string().required(requiredMessage).nullable(),
    conveyanceNum: Yup.string().required(requiredMessage).matches("^[a-zA-Z0-9\\s\\-]*$", alphaNumericSpaceRegx).nullable(),
    licenseNum: Yup.string().required(requiredMessage).matches("^[a-zA-Z0-9]*$", alphaNumericRegx).nullable(),
    countryCode: Yup.string().required(requiredMessage).nullable(),
    state: Yup.string().required(requiredMessage).nullable(),
    email: Yup.string().trim().email(emailValid).max(256, emailMaxLength).nullable(),
    sealNo1: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx), 
    sealNo2: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo3: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo4: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo5: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx), 
    sealNo6: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo7: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo8: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo9: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo10: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo11: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo12: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo13: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo14: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo15: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo16: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo17: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo18: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo19: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    sealNo20: Yup.string().nullable().matches("^[a-zA-Z0-9 ]*$", alphaNumericSpaceRegx),
    iit: Yup.string().nullable().matches("^[0-9]*$", numericRegx),
    lvs: Yup.string().nullable().matches("^[0-9]*$", numericRegx),
    postal: Yup.string().nullable().matches("^[0-9]*$", numericRegx),
    flyingTrucks: Yup.string().nullable().matches("^[0-9]*$", numericRegx),
    personDTO: Yup.array(),
    personDocumentDTO: Yup.array(),
    trailerDTO: Yup.array(),
  })
}

export default makeValidationSchema
