import React from 'react'
import { components, constants } from 'cng-web-lib'
import FormProperties from './TrailerDTOFormProperties'
import { Box, Grid } from '@material-ui/core'
import CngSection from 'src/components/cngcomponents/CngSection'
import CngField from 'src/components/cngcomponents/CngField'

const {
  button: { CngButton, CngPrimaryButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function TrailerDTODialog(props) {
  const {
    getLookupValue,
    getCountryStateLabel,
    onAddTrailerDTO,
    onEditTrailerDTO,
    isView,
    onClose,
    open,
    showNotification,
    trailerDTO,
    translatedTextsObject
  } = props

  const { initialValues, makeValidationSchema } = FormProperties.formikProps

  return (
    <CngDialog
      dialogContent={
        isView && trailerDTO ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CngSection title={translatedTextsObject.trailerDTOTitle}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={translatedTextsObject.cargoLoadedIn}>
                      {getLookupValue('cargoLoadedIn', trailerDTO.cargoLoadedIn)}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={translatedTextsObject.equipmentNumber}>
                      {trailerDTO.equipmentNumber}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={translatedTextsObject.licensePlateNum}>
                      {trailerDTO.licensePlateNum}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={translatedTextsObject.country}>
                      {getLookupValue('countries', trailerDTO.countryCode)}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={translatedTextsObject.state}>
                      {getCountryStateLabel(trailerDTO.countryCode, trailerDTO.stateCode)}
                    </CngField>
                  </Grid>
                </Grid>
              </CngSection>
            </Grid>
            <Grid item xs={12}>
              <CngSection title='Container No(s)'>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.container1}>
                      {trailerDTO.container1}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.container2}>
                      {trailerDTO.container2}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.container3}>
                      {trailerDTO.container3}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.container4}>
                      {trailerDTO.container4}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.container5}>
                      {trailerDTO.container5}
                    </CngField>
                  </Grid>
                </Grid>
              </CngSection>
            </Grid>
            <Grid item xs={12}>
              <CngSection title='Seal No(s)'>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo1}>
                      {trailerDTO.sealNo1}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo2}>
                      {trailerDTO.sealNo2}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo3}>
                      {trailerDTO.sealNo3}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo4}>
                      {trailerDTO.sealNo4}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo5}>
                      {trailerDTO.sealNo5}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo6}>
                      {trailerDTO.sealNo6}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo7}>
                      {trailerDTO.sealNo7}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo8}>
                      {trailerDTO.sealNo8}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo9}>
                      {trailerDTO.sealNo9}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo10}>
                      {trailerDTO.sealNo10}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo11}>
                      {trailerDTO.sealNo11}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo12}>
                      {trailerDTO.sealNo12}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo13}>
                      {trailerDTO.sealNo13}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo14}>
                      {trailerDTO.sealNo14}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo15}>
                      {trailerDTO.sealNo15}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo16}>
                      {trailerDTO.sealNo16}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo17}>
                      {trailerDTO.sealNo17}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo18}>
                      {trailerDTO.sealNo18}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo19}>
                      {trailerDTO.sealNo19}
                    </CngField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <CngField label={translatedTextsObject.sealNo20}>
                      {trailerDTO.sealNo20}
                    </CngField>
                  </Grid>
                </Grid>
              </CngSection>
            </Grid>
            <Grid item xs={12}>
              <CngSection title='Exemption Cargo(s)'>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.iit}>
                      {trailerDTO.iit}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.lvs}>
                      {trailerDTO.lvs}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.postal}>
                      {trailerDTO.postal}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.flyingTrucks}>
                      {trailerDTO.flyingTrucks}
                    </CngField>
                  </Grid>
                </Grid>
              </CngSection>
            </Grid>
          </Grid>
        ) : (
          <CngForm
            fieldLevel='form'
            formikProps={{
              initialValues: trailerDTO || initialValues,
              makeValidationSchema: makeValidationSchema,
              onSubmit: (data) => {
                if (trailerDTO) {
                  onEditTrailerDTO(data)
                } else {
                  onAddTrailerDTO(data)
                }

                onClose()
              }
            }}
            formState={FormState.COMPLETED}
            renderBodySection={(labelMap, shouldHideMap) => (
              <Box padding={1}>
                <FormProperties.Fields
                  showNotification={showNotification}
                  shouldHideMap={shouldHideMap}
                />
              </Box>
            )}
            innerForm={true}
            renderButtonSection={() => (
              <Box display='flex' justifyContent='flex-end'>
                <CngPrimaryButton type='submit'>
                  {trailerDTO
                    ? translatedTextsObject.updateButton
                    : translatedTextsObject.saveButton}
                </CngPrimaryButton>
              </Box>
            )}
          />
        )
      }
      dialogAction={
        isView ? <CngButton onClick={onClose}>OK</CngButton> : null
      }
      dialogTitle={translatedTextsObject.trailerDTOTitle}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default TrailerDTODialog
